import * as ThemeUI from 'theme-ui'
import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Utils from './utils'

export function PageContent(props) {
  return (
    <ThemeUI.Box
      sx={{
        ...Utils.Css.mixins.contentHeight(true),
      }}
    >
      {props.children}
    </ThemeUI.Box>
  )
}

PageContent.propTypes = {
  headerRef: PropTypes.object,
  children: PropTypes.node,
}
