import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'

import * as Icons from '../icons'
import * as Theming from '../theming/buttons'

export function Button(props) {
  return (
    <ThemeUI.Button
      as={props.as}
      disabled={props.disabled || props.loading}
      id={props.id}
      name={props.name}
      type={props.type}
      to={props.to}
      variant={props.variant}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props.sx,
      }}
      {...props}
      onClick={(event) => {
        if (!props.disabled && props.onClick) {
          props.onClick(event)
        }
      }}
    >
      {props.prependIcon}

      <ThemeUI.Box
        as="span"
        sx={{
          ...(props.prependIcon && { ml: 1 }),
          ...(props.appendIcon && { mr: 1 }),
        }}
      >
        {props.loading ? <Icons.Spinner /> : props.children}
      </ThemeUI.Box>

      {props.appendIcon}
    </ThemeUI.Button>
  )
}

Button.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  iconStart: PropTypes.node,
  id: PropTypes.string,
  iconEnd: PropTypes.node,
  name: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  to: PropTypes.string,
  variant: PropTypes.oneOf(Theming.buttonVariants),
  sx: PropTypes.object,
  prependIcon: PropTypes.node,
  appendIcon: PropTypes.node,

  onClick: PropTypes.func,
}

Button.defaultProps = {
  variant: 'secondary.outlined',
  as: 'button',
}
