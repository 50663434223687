import * as Constants from '../constants'

const gameCard = {
  borderRadius: 1,
  userSelect: 'none',
}

export const cards = {
  game: {
    small: {
      ...gameCard,
      height: [
        // Small * Multiplier is too small on mobile, so we don't use 'small'
        // height on mobile screens, just medium
        Constants.GameTileHeight.MEDIUM * Constants.GAME_TILE_MULTIPLIER,
        Constants.GameTileHeight.SMALL,
      ],
    },

    midi: {
      ...gameCard,
      height: [
        Constants.GameTileHeight.MIDI * Constants.GAME_TILE_MULTIPLIER,
        Constants.GameTileHeight.MIDI,
      ],
    },

    medium: {
      ...gameCard,
      height: [
        Constants.GameTileHeight.MEDIUM * Constants.GAME_TILE_MULTIPLIER,
        Constants.GameTileHeight.MEDIUM,
      ],
    },

    large: {
      ...gameCard,
      height: [
        Constants.GameTileHeight.LARGE * Constants.GAME_TILE_MULTIPLIER,
        Constants.GameTileHeight.LARGE,
      ],
    },
  },
}

export const gameCardVariants = ['small', 'medium', 'large']
