import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ThemeUI from 'theme-ui'

import * as Utils from './utils'

function RootLink(props) {
  return (
    <ReactRouter.Link to="/" component={Utils.Components.RootLink} {...props} />
  )
}

export function Logo() {
  return (
    <ThemeUI.Flex as={RootLink}>
      <ThemeUI.Image
        src="/images/logo.svg"
        alt="Mychance Logotype"
        height="35"
        width="120"
      />
    </ThemeUI.Flex>
  )
}
