import * as Urql from 'urql'

const getLocks = `
  query getLocks {
    player {
      locks {
        type
        options {
          messages {
            id
            title
            message
          }
        }
        rejected
        dismissable
      }
    }
  }
`

export function useLocks() {
  const [response, invalidate] = Urql.useQuery({
    query: getLocks,
    requestPolicy: 'cache-and-network',
  })

  const locks = response.data?.player?.locks ?? []

  /**
   * Invalidate locks callback
   * @name invalidate
   * @access public
   */
  function invalidateLocks() {
    invalidate()
  }

  return {
    /**
     * Locks
     *
     * @type {object[]}
     */
    types: locks,
    /**
     * If there's any locks present
     *
     * @type {boolean}
     */
    hasLocks: locks.length > 0,
    /**
     * Marks current lock data as stale and forces refetching
     *
     * @type {func}
     */
    invalidate: invalidateLocks,
    /**
     * Error
     * @type {object}
     */
    error: response.error,
    /**
     * Locks update status
     *
     * @type {boolean}
     */
    fetching: response.fetching,
  }
}

export function useLock(name) {
  const locks = useLocks()

  return locks.types?.find((lock) => lock.type === name)
}
