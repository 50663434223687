import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

export function Backdrop(props) {
  const location = ReactRouter.useLocation()

  if (!props.show) {
    return null
  }

  return (
    <ThemeUI.Flex
      as={ReactRouter.Link}
      to={props.closePathname ?? location.pathname}
      sx={{
        cursor: 'default',
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '999',
        left: '0px',
        top: '0px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
      onClick={props.clicked}
    />
  )
}

Backdrop.propTypes = {
  clicked: PropTypes.func,
  closePathname: PropTypes.string,
  show: PropTypes.bool,
}
