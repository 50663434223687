import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import fetch from 'node-fetch'
import {
  Provider,
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
} from 'urql'

import { devtoolsExchange } from '@urql/devtools'
import { suspenseExchange } from '@urql/exchange-suspense'

import * as Constants from './constants'
import * as Cookies from './cookies'
import * as AppConfig from './app-config'
import * as Http from './http'
import * as Logger from './logger'

function applyWhen(cond, exchange) {
  if (cond()) {
    return exchange
  }

  return ({ forward }) => forward
}

export function GraphqlProvider(props) {
  const acceptLanguage = Http.useRequestHeader('accept-language')
  const locale = Http.useLocale()
  const clientType = AppConfig.useClientType()
  const [countryCode] = AppConfig.useCountryCode()
  const [language] = AppConfig.useLanguage()
  const url = AppConfig.useGraphqlUrl()

  const [token] = Cookies.useCookie(Constants.Cookies.TOKEN)

  const client = React.useMemo(
    () =>
      createClient({
        exchanges: [
          applyWhen(
            () => process.env.NODE_ENV !== 'production',
            Logger.exchange
          ),
          applyWhen(
            () => process.env.NODE_ENV !== 'production',
            devtoolsExchange
          ),
          dedupExchange,
          applyWhen(() => Boolean(process.browser), suspenseExchange),
          cacheExchange,
          props.ssrCache,
          fetchExchange,
        ],
        fetch(input, init) {
          return new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
              reject(new Error('Request timed out'))
            }, 15000)

            fetch(input, init).then((response) => {
              clearTimeout(timeoutId)
              resolve(response)
            })
          })
        },
        fetchOptions: {
          agent: props.agent,
          headers: R.filter(Boolean, {
            'Accept-Language': acceptLanguage,
            'Authorization': token,
            'Frontend-Client-Type': clientType,
            'Frontend-Country-Code': locale.region || countryCode,
            'Frontend-Language': locale.language || language,
          }),
        },
        suspense: true,
        url,
      }),
    [
      acceptLanguage,
      clientType,
      countryCode,
      language,
      locale.language,
      locale.region,
      props.agent,
      props.ssrCache,
      token,
      url,
    ]
  )

  return <Provider value={client}>{props.children}</Provider>
}

GraphqlProvider.propTypes = {
  agent: PropTypes.object,
  children: PropTypes.element,
  ssrCache: PropTypes.func,
  url: PropTypes.string,
}
