import * as Utils from '../utils'

const buttonBase = {
  'borderRadius': 1,
  'color': 'white',
  'py': 1,
  'px': 2,
  'cursor': 'pointer',
  'fontSize': 'sm',
  'fontWeight': 800,
  'lineHeight': '18px',
  'textShadow': '0px 1px 0px rgba(0, 0, 0, 0.25)',
  'transform': 'color 150ms ease-in-out',

  // TODO: handle disabled state for <a> tags as well, e.g: as={ReactRouter.Link}
  '&:disabled': {
    cursor: 'not-allowed',
    color: 'primary',
    backgroundColor: 'primary-hover',
  },

  'svg path': {
    fill: 'currentColor',
  },
}

const buttonOutlineBase = {
  ...buttonBase,
  backgroundColor: 'transparent',
  outline: '1px solid',
  outlineColor: 'primary',
  color: 'primary',
}

export const buttons = {
  primary: {
    ...buttonBase,
    'transition': '.3s',
    'backgroundColor': 'primary',
    'boxShadow':
      '0px 1px 8px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset',
    '&:not([disabled])&:hover, &:not([disabled])&:active': {
      backgroundColor: 'primary-hover',
    },

    'outlined': {
      ...buttonOutlineBase,
      'color': 'primary',

      'svg path': {
        fill: 'currentColor',
      },
    },
  },

  outlined: {
    ...buttonOutlineBase,
  },

  secondary: {
    ...buttonBase,
    'backgroundColor': 'faded-white',
    'color': 'primary-text-inverted',
    '&:hover, &:active': {
      backgroundColor: 'secondary-hover',
    },

    'outlined': {
      ...buttonOutlineBase,
      'transition': '.3s',
      'color': 'primary',

      '&:hover, &:active': {
        outlineColor: 'primary-hover',
        color: 'primary-hover',
      },

      'svg path': {
        fill: 'currentColor',
      },
    },
  },

  sub: {
    ...buttonBase,
    backgroundColor: 'transparent',
    borderColor: 'faded-white',
    color: 'faded-white',
    border: '2px',
    pagination: {
      ...buttonBase,
      backgroundColor: 'transparent',
      borderColor: 'faded-white',
      color: 'faded-white',
      border: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mx: Utils.Theming.breakpoints({
        _: 0,
        sm: 1,
      }),
      width: Utils.Theming.breakpoints({
        _: '27px',
        sm: '60px',
      }),
      height: Utils.Theming.breakpoints({
        _: '30px',
        sm: 'auto',
      }),
    },
  },

  icon: {
    cursor: 'pointer',
  },

  text: {
    fontFamily: 'heading',
    backgroundColor: 'transparent',
    padding: '0px',
    textAlign: 'left',
    cursor: 'pointer',
  },
}

export const buttonVariants = [
  'primary',
  'secondary',
  'primary.outlined',
  'secondary.outlined',
  'outlined',
  'sub',
  'sub.pagination',
  'icon',
  'text',
]
