import * as DateFns from 'date-fns'

export function formatFullDateAndTime(date) {
  return DateFns.format(date, 'dd/MM/Y, H:mm:ss')
}

export function formatFullDate(date) {
  return DateFns.format(date, 'dd/MM/Y')
}

export function toKebabCase(str) {
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z0-9]*|\b)|[A-Z]?[a-z0-9]*|[A-Z]|[0-9]+/g)
      .filter(Boolean)
      .map((x) => x.toLowerCase())
      .join('-')
  )
}

export function mapToKebabCase(arr) {
  return arr && arr.map((item) => toKebabCase(item))
}
