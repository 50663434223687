import * as Utils from '../utils'

export const staticPageTemplate = {
  'h2': {
    fontSize: Utils.Theming.breakpoints({
      _: '2xl',
      sm: '3xl',
    }),
    mt: Utils.Theming.breakpoints({
      _: 5,
      sm: 9,
    }),
  },
  'h3': {
    fontSize: Utils.Theming.breakpoints({
      _: 'md',
      sm: 'xl',
    }),
  },
  'h3:not(:first-of-type)': {
    mt: Utils.Theming.breakpoints({
      _: 3,
      sm: 6,
    }),
  },
  'p': {
    fontSize: Utils.Theming.breakpoints({
      _: 'sm',
      sm: 'md',
    }),
  },
  'p:last-of-type': {
    mb: Utils.Theming.breakpoints({
      _: 5,
      sm: 9,
    }),
  },
}
