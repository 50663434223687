import * as React from 'react'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'

import { NavLink } from '../nav-link'

const footerNavBarLinks = [
  {
    labelKey: 'footer.menu.terms-conditions.label',
    to: '/terms-and-conditions',
  },
  { labelKey: 'footer.menu.about-us.label', to: '/about-us' },
  { labelKey: 'footer.menu.cookie-policy.label', to: '/cookie-policy' },
  {
    labelKey: 'footer.menu.affiliate',
    to: 'https://heropartners.io/',
    external: true,
  },
  { labelKey: 'footer.menu.faq.label', to: '/faq' },
  { labelKey: 'footer.menu.privacy-policy.label', to: '/privacy-policy' },
  {
    labelKey: 'footer.menu.responsible-gaming.label',
    to: '/responsible-gaming',
  },
  { labelKey: 'footer.menu.security.label', to: '/security' },
]

export function FooterNavBar() {
  const translate = Herz.I18n.useTranslate()

  const links = footerNavBarLinks

  return (
    <ThemeUI.Flex
      sx={{
        'flexDirection': 'column',
        'justifyContent': 'center',
        'flexShrink': 0,
        'fontSize': ['md', null, 'xs'],
        'gap': [2, null, 1],

        '& > *': {
          color: 'faded-white',
          lineHeight: 2,
          textDecoration: 'underline',
          fontWeight: '400',
          fontSize: 'xs',
        },
      }}
    >
      {links.map((linkItem) =>
        linkItem.external ? (
          <ThemeUI.Link
            href={linkItem.to}
            key={linkItem.to}
            target="_blank"
            rel="noreferrer"
            sx={{ fontSize: 'xs', textDecoration: 'none', fontWeight: 'bold' }}
          >
            {translate(linkItem.labelKey)}
          </ThemeUI.Link>
        ) : (
          <NavLink to={linkItem.to} key={linkItem.to}>
            {linkItem.title ? linkItem.title : translate(linkItem.labelKey)}
          </NavLink>
        )
      )}
    </ThemeUI.Flex>
  )
}

Herz.I18n.Loader.preload(
  footerNavBarLinks.map((link) => link.labelKey),
  FooterNavBar
)
