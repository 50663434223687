import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import * as Player from './redux-player'
import * as Suspense from './suspense'
import { Amount } from './amount'
import { Button } from './buttons/button'
import { useLocks } from './locks/use-locks'
import { useSafeUpdateQuery } from './use-safe-update-query'

export function BalanceButton() {
  const session = Herz.Auth.useSession()

  if (!session.authenticated) {
    return null
  }

  return (
    <Suspense.Boundary fallback={null}>
      <BalanceButtonImpl />
    </Suspense.Boundary>
  )
}

function BalanceButtonImpl() {
  const { balanceCents, bonusBalanceCents } = Player.useBalance()
  const { hasLocks } = useLocks()
  const depositQuery = useSafeUpdateQuery({ deposit: 'me' })
  const translate = Herz.I18n.useTranslate()
  const history = ReactRouter.useHistory()

  const totalBalance = balanceCents + bonusBalanceCents
  const isZeroBalance = Boolean(
    Number.isNaN(totalBalance) || totalBalance === 0
  )

  return (
    <Button
      onClick={() => history.push({ search: depositQuery })}
      variant="primary"
      disabled={hasLocks}
    >
      {isZeroBalance ? (
        translate('header.balance-button')
      ) : (
        <Amount>{totalBalance}</Amount>
      )}
    </Button>
  )
}

Herz.I18n.Loader.preload(['header.balance-button'], BalanceButtonImpl)
