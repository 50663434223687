import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Http from './http'

export function Router(props) {
  const request = Http.useRequest()

  const basename = Http.useBasename()

  if (process.browser) {
    return <ReactRouter.BrowserRouter {...props} basename={basename} />
  }

  return (
    <ReactRouter.StaticRouter
      {...props}
      basename={basename}
      location={request.url}
    />
  )
}

Router.defaultProps = {
  context: {},
}

Router.propTypes = {
  context: PropTypes.object,
}
