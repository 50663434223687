import * as React from 'react'
import * as ReactDOM from 'react-dom'
import UniversalCookie from 'universal-cookie'
import { ssrExchange } from 'urql'

import * as Herz from '@rushplay/herz'
import { loadableReady } from '@loadable/component'

import * as Suspense from './suspense'
import { App } from './app'
import { GlobalProvider } from './global-provider'
import { createStore } from './create-store'

const cookies = new UniversalCookie()

const ssrCache = ssrExchange({
  initialState: window.__GQL_DATA__,
  isClient: true,
})

const store = createStore({ initialState: window.__REDUX_STATE__ })

const i18nLoader = new Herz.I18n.Loader({
  transformAlias(key) {
    return `mychance.${key}`
  },
  url: `${window.__API_URL__}/translations/search`,
})

const element = document.querySelector('[data-reactroot]')

i18nLoader.restoreCache(window.__I18N_DATA__).then(() => {
  loadableReady(() => {
    ReactDOM.hydrate(
      <GlobalProvider
        cookies={cookies}
        i18nLoader={i18nLoader}
        ssrCache={ssrCache}
        store={store}
      >
        <Suspense.Boundary>
          <App />
        </Suspense.Boundary>
      </GlobalProvider>,
      element
    )
  })
})

if (module.hot) {
  module.hot.accept(['./app', './global-provider', './suspense'], () => {
    ReactDOM.render(
      <GlobalProvider
        cookies={cookies}
        i18nLoader={i18nLoader}
        ssrCache={ssrCache}
        store={store}
      >
        <Suspense.Boundary>
          <App />
        </Suspense.Boundary>
      </GlobalProvider>,
      element
    )
  })
}
