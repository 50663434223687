import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Promotions from './redux-promotions'
import * as AppConfig from './app-config'

export function useNewPromotionListener() {
  const hasNewPromotionAdded = ReactRedux.useSelector((state) =>
    Promotions.getHasPromotionAdded(state.promotions)
  )
  const dispatch = ReactRedux.useDispatch()
  const [countryCode] = AppConfig.useCountryCode()

  React.useEffect(() => {
    if (hasNewPromotionAdded) {
      dispatch(
        Promotions.fetch('', () => Promotions.updateHasPromotionsAdded(false))
      )
    }
  }, [dispatch, countryCode, hasNewPromotionAdded])
}
