import * as R from 'ramda'

import * as Constants from '../constants'

/**
 * @param {Array.<string|number>} xs List of values
 * @return Comma separated list of values
 */
export const list = R.join(',')

/**
 * @param url - url
 * @return CSS formatted url string
 */
export function url(url) {
  return `url(${url})`
}

export const mixins = {
  flexGapRow: (size) => {
    return {
      '> *:not(:last-child)': {
        mr: size,
      },
    }
  },
  flexGapColumn: (size) => {
    // Sometimes these 2 flexGap styles might be used together (e.g. responsive styles),
    // so these selectors need to be different, otherwise ThemeUi will overwrite it.
    return {
      '> *:nth-last-of-type(n+2)': {
        mb: size,
      },
    }
  },
  contentHeight(minHeight) {
    const styleProperty = minHeight ? 'minHeight' : 'height'
    return {
      [styleProperty]: `calc(var(${Constants.CssVariables.WINDOW_INNER_HEIGHT}, 100vh) - var(${Constants.CssVariables.HEADER_WRAPPER_HEIGHT}, 0px))`,
    }
  },
}

export function setGlobalVariable(name, value) {
  if (typeof window === 'undefined') {
    return
  }

  document.documentElement.style.setProperty(name, value)
}

export function px(value) {
  return `${value}px`
}
