import * as Redux from 'redux'
import effects from 'redux-effects'
import fetch from 'redux-effects-fetch'
import multi from 'redux-multi'
import { composeWithDevTools } from 'redux-devtools-extension'

import * as Analytics from '@rushplay/analytics'
import * as Casino from '@rushplay/casino'
import * as Gtm from '@rushplay/analytics/gtm'
import * as Netrefer from '@rushplay/analytics/netrefer'
import * as Maps from '@rushplay/analytics/maps'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'
import * as SupportChat from '@rushplay/support-chat'
import * as Websockets from '@rushplay/websockets'
import * as Notifications from '@rushplay/notifications'
import * as Offers from '@rushplay/offers'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Limits from '@rushplay/compliance/limits'

import * as Cookies from './redux-cookies'
import * as Configuration from './redux-configuration'
import * as Inventory from './redux-inventory'
import * as Player from './redux-player'
import * as Promotions from './redux-promotions'
import * as Session from './redux-session'
import { gtmEvents, mapsEvents, netreferEvents } from './analytics'

export function createStore(options = {}) {
  return Redux.createStore(
    Redux.combineReducers({
      analytics: Analytics.reducer,
      api: Api.reducer,
      configuration: Configuration.reducer,
      casino: Casino.reducer,
      inventory: Inventory.reducer,
      jurisdiction: Jurisdiction.reducer,
      limits: Limits.reducer,
      notifications: Notifications.reducer,
      player: Player.reducer,
      processes: Processes.reducer,
      promotions: Promotions.reducer,
      session: Session.reducer,
      supportChat: SupportChat.reducer,
      offers: Offers.reducer,
    }),
    options.initialState,
    composeWithDevTools({})(
      Redux.applyMiddleware(
        multi,
        Websockets.middleware({
          bindings: {
            [Websockets.SESSION_EXPIRED]: () => {
              return [
                Session.expired(true),
                Notifications.add({
                  message: 'session-expired',
                  level: 'info',
                }),
              ]
            },
            [Websockets.SHUFTI_PRO_UPLOAD_FEEDBACK]: (data) =>
              data?.declinedReasons.includes(
                'Address on the document does not match with the provided one'
              )
                ? Notifications.add({
                    message: `my-identity.status.verification.address-mismatch.failure`,
                    level: 'error',
                    variables: {
                      declinedReasons: `<ul>
                    ${data?.declinedReasons?.map(
                      (reason) => `<li>${reason}</li>`
                    )}
                  </ul>`,
                    },
                  })
                : Notifications.add({
                    message: data.validUpload
                      ? `my-identity.status.verification.${data.lastUploadedDocumentType}.success`
                      : `my-identity.status.verification.${data.lastUploadedDocumentType}.failure`,
                    level: data.validUpload ? 'success' : 'error',
                    variables: {
                      declinedReasons: data.declinedReasons
                        ? `
                    <ul>
                      ${data?.declinedReasons?.map(
                        (reason) => `<li>${reason}</li>`
                      )}
                    </ul>
                  `
                        : null,
                    },
                  }),
          },
        }),
        Api.middleware({
          countryCodeSelector: (state) =>
            Configuration.getCountryCode(state.configuration),
          defaultCacheFor: process.browser
            ? // CLIENT: No caching by default, needs to be set to 1 ms as 0
              // disables caching hence causes requests on hydration
              1
            : // SERVER: Roughly the average time to load page on client
              // completetly. This ensures we reuse cache from server requests,
              // but let client fetch fresh data if needed.
              30000,
          host: (state) => Configuration.getApiUrl(state.configuration),
          substateSelector: (state) => state.api,
          tokenSelector: (state) => Session.getToken(state.session),
        }),
        effects,
        fetch,
        Cookies.middleware(options.cookies),
        Analytics.eventQueuingMiddleware({
          events: [gtmEvents, netreferEvents, mapsEvents],
          queueSelector: (state) => Analytics.getEventsQueue(state.analytics),
          onError: (error) => {
            if (process.browser && window.onerror) {
              window.onerror(error)
            }
          },
        }),
        Gtm.middleware(gtmEvents),
        Netrefer.middleware(netreferEvents, {
          // TODO: Get from BE (configuration)
          clientId: '8d908ecd-f205-408a-9f5a-acbb0c8c11c9',
          endpoint: 'http://tracking.netrefer.com/Tracking.svc/Track',
        }),
        Maps.middleware(mapsEvents, {
          endpoint: 'https://creatives.heropartners.io/masterpostback.aspx',
          key: 'so9kmdauot7syi2x',
          reportId: '2',
        })
      )
    )
  )
}
