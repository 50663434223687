import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import { lazy as loadable } from '@loadable/component'

import * as AppConfig from './app-config'
import * as Configuration from './redux-configuration'
import { PrivateRoute } from './private-route'
import { useLocks } from './locks/use-locks'

const CasinoPage = loadable(() => import('./casino-page'))
const ChangeLocationPage = loadable(() => import('./change-location-page'))
const DevTools = loadable(() => import('./dev-tools'))
const LandingPage = loadable(() => import('./landing-page'))
const LiveCasinoPage = loadable(() => import('./live-casino-page'))
const LocksRouter = loadable(() => import('./locks'))
const NotFound = loadable(() => import('./not-found'))
const PlayPage = loadable(() => import('./play-page'))
const PromotionsPage = loadable(() => import('./promotions-page'))
const PromotionTermsConditions = loadable(() =>
  import('./promotion-terms-conditions')
)
const SettingsPage = loadable(() => import('./settings-page'))
const SportsPage = loadable(() => import('./sports'))
const SportsComingSoon = loadable(() => import('./sports-coming-soon'))
const StaticPage = loadable(() => import('./static-page'))
const UserDetails = loadable(() => import('./user-details'))

export function Routes() {
  const location = ReactRouter.useLocation()
  const { hasLocks } = useLocks()
  const betby = AppConfig.useBetbyConfig()
  const env = ReactRedux.useSelector((state) =>
    Configuration.getEnvironment(state.configuration)
  )

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  if (hasLocks) {
    return <LocksRouter />
  }

  return (
    <ReactRouter.Switch>
      <ReactRouter.Route exact path="/" component={LandingPage} />

      <ReactRouter.Route path="/dev-tools" component={DevTools} />

      <ReactRouter.Redirect
        exact
        path="/password/:token"
        to="/?forgot-password=me&token=:token"
      />

      <ReactRouter.Redirect
        from="/locks"
        to={{ pathname: '/', search: location.search }}
      />

      <ReactRouter.Route path="/play/:gameId" component={PlayPage} />

      <ReactRouter.Route path="/play-live/:gameId" component={PlayPage} />

      <ReactRouter.Route path="/casino" component={CasinoPage} />

      <PrivateRoute path="/settings/user-details" component={UserDetails} />

      <ReactRouter.Route
        path="/sports"
        component={betby.enabled ? SportsPage : SportsComingSoon}
      />

      <ReactRouter.Route
        path="/promotions/:type"
        exact
        component={PromotionsPage}
      />

      <ReactRouter.Route
        path="/promotions/:type/:id"
        component={PromotionTermsConditions}
      />

      <ReactRouter.Redirect exact to="/promotions/all" path="/promotions" />

      <ReactRouter.Route
        path="/live-casino/:category?"
        component={LiveCasinoPage}
      />

      <PrivateRoute path="/settings" component={SettingsPage} />

      {env === 'staging' && (
        <ReactRouter.Route
          path="/change-location"
          component={ChangeLocationPage}
        />
      )}

      <ReactRouter.Redirect
        exact
        to="/terms-and-conditions/general"
        path="/terms-and-conditions"
      />
      <ReactRouter.Redirect exact to="/faq/my-account" path="/faq" />
      <ReactRouter.Route
        path={[
          '/(terms-and-conditions/welcome-offer)',
          betby.enabled && '/(terms-and-conditions/sports)',
          '/(terms-and-conditions/general)',
          '/(about-us)',
          '/(cookie-policy)',
          '/(faq/my-account)',
          '/(faq/bonuses)',
          '/(faq/licensing-and-security)',
          '/(faq/mobile)',
          '/(faq/payments)',
          '/(faq/responsible-gaming)',
          '/(faq/technical-issues)',
          '/(privacy-policy)',
          '/(responsible-gaming)',
          '/(security)',
        ].filter(Boolean)}
        component={StaticPage}
      />
      <ReactRouter.Route component={NotFound} />
    </ReactRouter.Switch>
  )
}
