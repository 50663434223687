import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as Icons from './icons'
import PropTypes from 'prop-types'

const SuspenseContext = React.createContext()

export class Provider extends React.PureComponent {
  constructor() {
    super()
    this.state = { enabled: false }
  }

  componentDidMount() {
    this.setState({ enabled: true })
  }

  render() {
    return (
      <SuspenseContext.Provider value={this.state}>
        {this.props.children}
      </SuspenseContext.Provider>
    )
  }
}

Provider.propTypes = {
  children: PropTypes.node,
}

// This component is a workaround for hydration issues, where huge parts of the
// tree get discarded due to Suspense boundary presence.
//
// Reconciler treats them as special kind of element that must be denoted in
// markup produced by server. Since Suspense is not supported in server
// renderer yet, there is no markup for Suspense boundaries in HTML.
//
// Therefore if we render `React.Suspense` into tree, but there’s nothing from
// server that says there should be boundary, it discards whole subtree.
// Previous implementation of Suspense hack looked like this:
//
//     export function Suspense(props) {
//       if (process.browser) {
//         return <React.Suspense {...props} />
//       }
//
//       return props.children
//     }
//
// Since Suspense is throwing Promises to stop rendering subtree, error
// boundary is used to catch exception and swap plain tree with tree wrapped
// with `<Suspense>`. This causes rerender and would lead to another exception
// to be thrown if conditions haven’t changed. If the exception was “Suspense
// promise”, it will be processed correctly by injected Suspense boundary, but
// if it was regular error, Suspense-injecting error boundary will just rethrow
// it and it will be processed by next error boundary in tree.
export class Boundary extends React.PureComponent {
  constructor(props, context) {
    super(props, context)

    // Use value from context to avoid changing component tree when Suspense is
    // ready to be used; this will force Suspense boundary to be present on
    // first component render if possible.
    this.state = { hasSuspense: context.enabled }
  }

  componentDidCatch(error) {
    if (!this.context.enabled || this.state.hasSuspense) {
      // React Reconciler will process thrown promises, so if another error is
      // thrown, we rethrow it to be caught by some real error boundary.
      throw error
    } else {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Injecting suspense boundary...')
      }
      this.setState({ hasSuspense: true })
    }
  }

  render() {
    // Server-side rendering does not support Suspense yet, so it must be used
    // only on client-side
    if (process.browser && this.state.hasSuspense) {
      return <React.Suspense {...this.props} />
    }

    return this.props.children
  }
}

Boundary.contextType = SuspenseContext

Boundary.defaultProps = {
  children: null,
  fallback: (
    <ThemeUI.Flex
      sx={{
        justifyContent: 'center',
        pt: '64px',
        height: '100%',
        fontSize: ['48px', '64px'],
      }}
    >
      <Icons.Spinner />
    </ThemeUI.Flex>
  ),
}

Boundary.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node,
}
