import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Suspense from '../suspense'

import { QueryDrawer } from './drawer'

const DepositDrawer = loadable(() => import('./deposit-drawer'))
const InventoryDrawer = loadable(() => import('./inventory-drawer'))
const LoginDrawer = loadable(() => import('./login-drawer'))
const NavDrawer = loadable(() => import('./nav-drawer'))
const RegisterDrawer = loadable(() => import('./register-drawer'))
const WithdrawDrawer = loadable(() => import('./withdraw-drawer'))
const ForgotPasswordDrawer = loadable(() => import('./forgot-password-drawer'))

export function AppDrawers() {
  const location = ReactRouter.useLocation()
  const history = ReactRouter.useHistory()
  const session = Herz.Auth.useSession()

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    if (session.authenticated) {
      if (queryParams.has('login') || queryParams.has('register')) {
        history.replace(history.location.pathname)
      }
    }

    if (!session.authenticated) {
      if (
        queryParams.has('deposit') ||
        queryParams.has('withdraw') ||
        queryParams.has('promotions')
      ) {
        history.replace(history.location.pathname)
      }
    }
  }, [history, location.search, session.authenticated])

  return (
    <Suspense.Boundary>
      <QueryDrawer query="login">
        <LoginDrawer />
      </QueryDrawer>
      <QueryDrawer keepMounted query="register">
        <RegisterDrawer />
      </QueryDrawer>
      <QueryDrawer query="deposit">
        <DepositDrawer />
      </QueryDrawer>
      <QueryDrawer query="side-nav">
        <NavDrawer />
      </QueryDrawer>
      <QueryDrawer query="promotions" queryValue="inventory">
        <InventoryDrawer />
      </QueryDrawer>
      <QueryDrawer query="withdraw">
        <WithdrawDrawer />
      </QueryDrawer>
      <QueryDrawer query="forgot-password">
        <ForgotPasswordDrawer />
      </QueryDrawer>
    </Suspense.Boundary>
  )
}
