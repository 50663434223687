import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Analytics from '@rushplay/analytics'
import * as Herz from '@rushplay/herz'
import * as Offers from '@rushplay/offers'

import * as Player from './redux-player'
import * as Promotions from './redux-promotions'
import * as _Analytics from './analytics'

export function useSessionListener() {
  const dispatch = ReactRedux.useDispatch()
  const session = Herz.Auth.useSession()

  React.useEffect(() => {
    if (session.authenticated) {
      dispatch([
        _Analytics.analyticsRegistration(),
        Analytics.authenticate(),
        Player.fetchPlayerInfo(),
        Promotions.fetch(),
        Offers.fetchDepositOffers(),
      ])
    } else {
      dispatch([Player.clearPlayerInfo(), Promotions.fetch()])
    }
  }, [dispatch, session.authenticated])
}
