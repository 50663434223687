import * as Reselect from 'reselect'

import * as Offers from '@rushplay/offers'

import * as Player from './redux-player'
import { getActivePromotions } from './redux-promotions'

function getAmountCents(_, props) {
  return props?.amountCents
}

export const getCalculatedOffers = Reselect.createSelector(
  [
    (state) => state.offers,
    getAmountCents,
    (state) => Player.getDepositCount(state.player),
  ],
  (offers, amountCents = 0, depositNumber = 0) =>
    Offers.getCalculatedOffers(offers, { amountCents, depositNumber })
)

export function getActivePromotionsByDepositTierFactory(props = {}) {
  return Reselect.createSelector(
    (state) => state.player.depositNumber,
    (state) => getActivePromotions(state.promotions, props),
    (playerDepositNumber, promotions) => {
      return promotions.filter((promotion) => {
        const minDepositNumber = promotion?.minDepositNumber ?? 0
        const maxDepositNumber = promotion?.maxDepositNumber ?? Infinity

        // No player deposit number means the user is logged out.
        // Logged out users should see all promotions.
        if (playerDepositNumber == null) {
          return true
        }

        return isWithinMinInclusiveRange(
          minDepositNumber,
          maxDepositNumber,
          playerDepositNumber
        )
      })
    }
  )
}

function isWithinMinInclusiveRange(min, max, number) {
  return number >= min && number < max
}
