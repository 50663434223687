import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'
import * as ReactRedux from 'react-redux'

import * as Herz from '@rushplay/herz'

import * as Promotions from './redux-promotions'
import * as Inventory from './redux-inventory'
import { BalanceButton } from './balance-button'
import { Button } from './buttons/button'
import { HamburgerMenu } from './icons'
import { MenuNotificationBadge } from './menu-notification-badge'
import { useActivePromotions } from './use-active-promotions'
import { useSafeUpdateQuery } from './use-safe-update-query'

export function NavActionButtons(props) {
  const history = ReactRouter.useHistory()
  const session = Herz.Auth.useSession()
  const translate = Herz.I18n.useTranslate()
  const sideNavQuery = useSafeUpdateQuery({ 'side-nav': 'me' })
  const loginQuery = useSafeUpdateQuery({ login: 'me' })
  const registerQuery = useSafeUpdateQuery({ register: 'me' })
  const closeSideNavQuery = useSafeUpdateQuery({ 'side-nav': null })
  const hasUnseenInventory = ReactRedux.useSelector((state) =>
    Inventory.hasUnseenItems(state.inventory)
  )
  const promotions = useActivePromotions()
  const hasUnseenPromotions =
    session.authenticated && Promotions.hasUnseenPromotions(promotions)

  function handleSideNavToggle() {
    const isSideNavOpen =
      history?.location?.search &&
      history?.location?.search.includes('side-nav=me')

    const updatedQuery = isSideNavOpen ? closeSideNavQuery : sideNavQuery
    history.push(`?${updatedQuery}`)
  }

  return (
    <ThemeUI.Flex
      sx={{
        ...props.sx,
        alignItems: 'center',
        gap: 1,
      }}
    >
      {!session.authenticated && (
        <React.Fragment>
          <Button
            as={ReactRouter.Link}
            to={`?${loginQuery}`}
            variant="secondary.outlined"
          >
            {translate('header.login-button')}
          </Button>
          <Button
            as={ReactRouter.Link}
            to={`?${registerQuery}`}
            variant="primary"
          >
            {translate('header.register-button')}
          </Button>
        </React.Fragment>
      )}

      <BalanceButton />

      <MenuNotificationBadge
        hasUnseenItems={hasUnseenInventory || hasUnseenPromotions}
      >
        <HamburgerMenu onClick={() => handleSideNavToggle()} />
      </MenuNotificationBadge>
    </ThemeUI.Flex>
  )
}

Herz.I18n.Loader.preload(
  ['header.login-button', 'header.register-button'],
  NavActionButtons
)

NavActionButtons.propTypes = {
  sx: PropTypes.object,
}
