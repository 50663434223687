import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'
import * as ReactRouter from 'react-router-dom'

import * as Utils from '../utils'
import * as Icons from '../icons'
import { Backdrop } from '../backdrop'
import { useSafeUpdateQuery } from '../use-safe-update-query'
import { useSearchQuery } from '../use-search-query'

Drawer.propTypes = {
  children: PropTypes.node,
  closePathname: PropTypes.string,
  draweType: PropTypes.oneOf(['login', 'register', 'side-nav']),
  /**
   * Force children to stay mounted after drawer is closed.
   */
  keepMounted: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
}

function useKeepMounted(enabled, open) {
  // Use state as we don’t want to mount component until drawer is open
  const [keepMounted, setKeepMounted] = React.useState(false)

  React.useEffect(() => {
    if (enabled) {
      if (open) {
        // Keep mounted after drawer is opened
        setKeepMounted(true)
      }
    } else {
      // Do not keep mounted if asked not to
      setKeepMounted(false)
    }
  }, [enabled, open])

  return keepMounted
}

export function Drawer(props) {
  const keepMounted = useKeepMounted(props.keepMounted, props.open)
  useScrollLock(props.open)

  if (!props.open && !keepMounted) {
    return null
  }

  return (
    <React.Fragment>
      <Backdrop
        closePathname={props.closePathname}
        show={props.open}
        clicked={props.onClose}
      />

      <ThemeUI.Flex
        sx={{
          display:
            !props.open && keepMounted
              ? 'none !important'
              : 'initial !important',
          flexDirection: 'column',
          width: ['100%', null, '412px'],
          mx: 'auto',
          position: 'fixed',
          right: '0px',
          top: '0px',
          backgroundColor: 'dark-bg',
          pt: 3,
          px: 2,
          height: '100%',
          zIndex: 'highest',
          overflowY: 'auto',
        }}
      >
        {props.draweType !== 'side-nav' && (
          <ThemeUI.IconButton
            as={ReactRouter.Link}
            to={props.closePathname ?? location.pathname}
            sx={{
              position: 'absolute',
              right: 1,
              fontSize: '24px',
              color: 'primary',
            }}
            onClick={props.onClose}
          >
            <Icons.Close />
          </ThemeUI.IconButton>
        )}

        {props.children}
      </ThemeUI.Flex>
    </React.Fragment>
  )
}

Drawer.Content = DrawerContent
Drawer.Title = DrawerTitle
Drawer.Heading = DrawerHeading

DrawerContent.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}

function DrawerContent(props) {
  return (
    <ThemeUI.Box
      sx={{
        ...props.sx,
      }}
    >
      {props.children}
    </ThemeUI.Box>
  )
}

DrawerTitle.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}

function DrawerTitle(props) {
  return (
    <ThemeUI.Text
      sx={{
        fontFamily: 'heading',
        fontWeight: 800,
        color: 'white',
        fontSize: '25px',
        textAlign: 'center',
        lineHeight: '32px',
        flexGrow: 1,
        ...props.sx,
      }}
      as="h2"
    >
      {props.children}
    </ThemeUI.Text>
  )
}

DrawerHeading.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}

function DrawerHeading(props) {
  return (
    <ThemeUI.Box
      sx={{
        ...props.sx,
      }}
    >
      {props.children}
    </ThemeUI.Box>
  )
}

export function QueryDrawer(props) {
  const searchQuery = useSearchQuery()
  const location = ReactRouter.useLocation()

  const queryValue = searchQuery?.[props.query]
  const isOpen = props.queryValue
    ? queryValue == props.queryValue
    : Boolean(queryValue)

  const closeQuery = useSafeUpdateQuery({ [props.query]: null })
  const closePathname = [location.pathname, `?${closeQuery}`].join('')

  return (
    <Drawer
      open={isOpen}
      closePathname={closePathname}
      keepMounted={props.keepMounted}
      onClose={props.onClose}
      draweType={Object.keys(searchQuery)[0]}
    >
      {props.children}
    </Drawer>
  )
}

QueryDrawer.propTypes = {
  children: PropTypes.node,
  query: PropTypes.string,
  queryValue: PropTypes.string,
  onClose: PropTypes.func,
  keepMounted: PropTypes.bool,
}

function useScrollLock(isLocked) {
  React.useEffect(() => {
    if (isLocked) {
      Utils.Css.setGlobalVariable('overflow', 'hidden')
    } else {
      Utils.Css.setGlobalVariable('overflow', 'auto')
    }

    return () => {
      Utils.Css.setGlobalVariable('overflow', 'auto')
    }
  }, [isLocked])
}
