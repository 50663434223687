import * as React from 'react'
import * as ThemeUI from 'theme-ui'

import * as AppConfig from '../app-config'
import * as Constants from '../constants'
import * as Utils from '../utils'
import { Logo } from '../logo'
import { NavActionButtons } from '../nav-action-buttons'

export function Header() {
  const headerWrapperRef = React.useRef()
  const [, setHeaderHeight] = AppConfig.useHeaderHeight()

  React.useEffect(() => {
    const headerWrapper = headerWrapperRef.current
    function setHeaderHeightVariable(value) {
      Utils.Css.setGlobalVariable(
        Constants.CssVariables.HEADER_WRAPPER_HEIGHT,
        value
      )
    }

    setHeaderHeightVariable(
      Utils.Css.px(headerWrapper.getBoundingClientRect().height)
    )

    const observer = new ResizeObserver(() => {
      const height = headerWrapper.getBoundingClientRect().height
      setHeaderHeight(Math.round(height))
      setHeaderHeightVariable(Utils.Css.px(height))
    })

    observer.observe(headerWrapper)
    return () => {
      observer.unobserve(headerWrapper)
    }
  }, [setHeaderHeight])

  return (
    <ThemeUI.Box
      sx={{
        position: 'sticky',
        width: '100%',
        top: '0px',
        left: '0px',
        zIndex: 'higher',
        borderBottom: '1px solid',
        borderColor: 'secondary-bg',
      }}
      ref={headerWrapperRef}
    >
      <ThemeUI.Grid
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'nav-bg',
          gridTemplateAreas: '"logo nav-action-buttons"',
          gridTemplateColumns: ['120px 1fr', null, '140px 1fr'],
          gridGap: '0px',
        }}
      >
        <ThemeUI.Box
          sx={{
            gridArea: 'logo',
            display: 'flex',
            justifyContent: 'flex-start',
            px: '16px',
          }}
        >
          <Logo />
        </ThemeUI.Box>

        <NavActionButtons
          sx={{
            p: [1, null, 2],
            gridArea: 'nav-action-buttons',
            justifySelf: 'end',
          }}
        />
      </ThemeUI.Grid>
    </ThemeUI.Box>
  )
}
