import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'
import { lazy as loadable } from '@loadable/component'

import * as Suspense from './suspense'
import * as Utils from './utils'

const Toast = loadable(() => import('./toast'))

const acceptedLevels = ['info', 'warning', 'error', 'success']

export function NotificationsContainer() {
  const translate = Herz.I18n.useTranslate()

  const notifications = ReactRedux.useSelector((state) => state.notifications)

  if (R.isEmpty(R.reject(R.isNil, notifications))) {
    return null
  }

  return (
    <ThemeUI.Box
      sx={{
        zIndex: 'highest',
        position: 'fixed',
        top: 1,
        right: 1,
        ...Utils.Css.mixins.flexGapColumn(1),
      }}
    >
      <Suspense.Boundary fallback={null}>
        <Notifications.Notifications mountPoint="notifications">
          {(notificationIds) =>
            notificationIds.map((notificationId) => (
              <Notifications.Notification
                key={notificationId}
                mountPoint="notifications"
                id={notificationId}
              >
                {(notification) => {
                  const level = acceptedLevels.includes(notification.level)
                    ? notification.level
                    : 'error'

                  return (
                    <Toast
                      onDismiss={() => {
                        notification.onDismiss(notificationId)
                      }}
                      variant={notification.level}
                      title={translate(`toast.${level}`)}
                      contentTranslationKey={notification.message}
                      contentTranslationVars={notification.variables}
                    />
                  )
                }}
              </Notifications.Notification>
            ))
          }
        </Notifications.Notifications>
      </Suspense.Boundary>
    </ThemeUI.Box>
  )
}

Herz.I18n.Loader.preload(
  ['toast.success', 'toast.warning', 'toast.error', 'toast.info'],
  NotificationsContainer
)
