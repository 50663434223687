import * as R from 'ramda'
import { breakpointsToArrayIndex } from '../theming/foundations'

export function breakpoints(breakpoints) {
  if (R.isNil(breakpoints)) {
    return []
  }

  const breakpointArray = []

  const breakpointKeys = Object.keys(breakpointsToArrayIndex)
  breakpointKeys.forEach((breakpointKey) => {
    breakpointArray[breakpointsToArrayIndex[breakpointKey]] =
      breakpoints[breakpointKey]
  })

  return breakpointArray
}
