import * as React from 'react'
import * as ThemeUI from 'theme-ui'

import * as Herz from '@rushplay/herz'

import * as Cookies from './cookies'
import * as Constants from './constants'
import { Button } from './buttons/button'
import { HtmlContent } from './html-content'

export function CookieConsentBanner() {
  const [cookieConsent, setCookieConsent] = Cookies.useCookie(
    Constants.Cookies.COOKIE_CONSENT
  )
  const [visible, setVisible] = React.useState(!cookieConsent)
  const translate = Herz.I18n.useTranslate()

  function handleSubmit() {
    setCookieConsent(
      true,
      Constants.CookieOptions[Constants.Cookies.COOKIE_CONSENT]
    )
    setVisible(false)
  }

  return (
    visible && (
      <ThemeUI.Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          bottom: '0px',
          left: '0px',
          right: '0px',
          padding: 1,
          zIndex: 'high',
          fontSize: 'xs',
          backgroundColor: 'cookie-banner-background',
          color: 'primary-text-inverted',
          lineHeight: '1.25',
        }}
      >
        <HtmlContent>{translate('cookie-disclaimer')}</HtmlContent>
        <Button
          onClick={handleSubmit}
          sx={{
            fontSize: 'xs',
            ml: 2,
            flexShrink: 0,
            color: 'primary-text-inverted',
          }}
        >
          {translate('ok')}
        </Button>
      </ThemeUI.Box>
    )
  )
}

Herz.I18n.Loader.preload(['ok', 'cookie-disclaimer'], CookieConsentBanner)
