export const forms = {
  select: {
    'backgroundColor': 'transparent',
    'border': 0,
    'color': 'input-text',
    'flexGrow': 1,
    'flexShrink': 1,
    'fontFamily': 'body',
    'fontSize': '16px',
    'py': '12px',
    'paddingLeft': '8px',
    'width': '100%',
    'appearance': 'none',

    '&:focus': { outline: 'none' },
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'input-text-disabled',
    },
    ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active':
      {
        webkitBoxShadow: '0 0 0 30px input-background inset !important',
      },
    'option': {
      color: 'black',
    },
  },

  input: {
    'width': '100%',
    'flexGrow': 1,
    'flexShrink': 1,
    'fontFamily': 'body',
    'fontSize': '16px',
    'py': '12px',
    'color': 'input-text',
    'backgroundColor': 'transparent',
    'border': 0,

    '&::placeholder': {
      color: 'input-placeholder',
    },

    '&:focus': { outline: 'none' },

    '&:disabled': {
      cursor: 'not-allowed',
      color: 'input-text-disabled',
    },

    '&::-ms-reveal, &::-ms-clear': {
      display: 'none',
    },
    '&:-webkit-autofill': {
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: '#EAEAEA',
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },

  label: {
    fontSize: '15px',
    fontFamily: 'heading',
    fontWeight: 600,
    letterSpacing: '0.03em',
    pb: 0,
    lineHeight: '14px',
    color: 'form-label',
    display: 'block',
  },
}
