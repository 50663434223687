import * as ThemeUI from 'theme-ui'
import * as PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'

import * as Herz from '@rushplay/herz'

import * as Icons from './icons'
import * as Utils from './utils'
import { Button } from './buttons/button'

export function ErrorFallback() {
  const translate = Herz.I18n.useTranslate()

  return (
    <ThemeUI.Box
      sx={{
        p: 6,
        height: '100%',
        backgroundColor: 'primary-bg',
      }}
    >
      <ThemeUI.Flex
        sx={{
          width: '90%',
          backgroundColor: 'nav-bg',
          mx: 'auto',
          p: 3,
          alignItems: 'center',
          flexDirection: 'column',
          ...Utils.Css.mixins.contentHeight(true),
        }}
      >
        <Icons.Info />

        <ThemeUI.Text sx={{ mt: 2 }} variant="default">
          {translate('error-boundary.title')}
        </ThemeUI.Text>

        <ThemeUI.Link
          sx={{
            textDecoration: 'none',
            mt: 4,
          }}
          href="/"
        >
          <Button>{translate('error-boundary.action')}</Button>
        </ThemeUI.Link>
      </ThemeUI.Flex>
    </ThemeUI.Box>
  )
}

Herz.I18n.Loader.preload(
  ['error-boundary.title', 'error-boundary.action'],
  ErrorFallback
)

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func,
}
