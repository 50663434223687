import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as AppConfig from './app-config'
import * as Http from './http'
import * as Utils from './utils'

export function LocaleRedirect(props) {
  const { locale, country } = Utils.Configuration.useConfiguration()
  const [, setPlayerLocale] = AppConfig.useLocale()
  const location = ReactRouter.useLocation()
  const prevBasename = Http.useBasename()
  const url = Http.useUrl()

  const nextBasename = `/${locale.slug}`
  const isLocaleIncorrect = prevBasename !== nextBasename
  const isRedirected =
    location.state?.prevBasename == null && location.state?.nextBasename == null

  React.useEffect(() => {
    setPlayerLocale(locale.language, country.alpha2)
  }, [])

  React.useEffect(() => {
    if (isLocaleIncorrect && isRedirected) {
      window.location.replace(`${nextBasename}${url}`)
    }
  }, [isLocaleIncorrect, isRedirected, nextBasename, url])

  if (isLocaleIncorrect) {
    // Perform redirect if was not performed yet
    if (location.state?.nextBasename == null) {
      return (
        <ReactRouter.Redirect
          to={{
            pathname: location.pathname,
            search: location.search,
            state: {
              ...location.state,
              prevBasename,
              nextBasename,
            },
          }}
        />
      )
    }

    // Render nothing while redirecting
    return null
  }

  // Render children if locale is correct
  return props.children
}

LocaleRedirect.propTypes = {
  children: PropTypes.node,
}
