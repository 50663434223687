import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { useSelector } from 'react-redux'

import * as Websockets from '@rushplay/websockets'
import * as Herz from '@rushplay/herz'

import * as Utils from './utils'
import * as Player from './redux-player'

export function useWebSocketListener() {
  const dispatch = ReactRedux.useDispatch()
  const session = Herz.Auth.useSession()
  const username = useSelector((state) => Player.getUsername(state.player))
  const { pusher } = Utils.Configuration.useConfiguration()

  // Init and re-init on token change
  React.useEffect(() => {
    dispatch(
      Websockets.init(pusher.key, {
        ...(session.authenticated &&
          username && {
            auth: {
              params: {
                username,
                sessionToken: session.token,
              },
            },
          }),
        cluster: pusher.cluster,
        authEndpoint: pusher.authEndpoint,
        encrypted: pusher.encrypted,
        enabledTransports: pusher.enabledTransports,
      })
    )
  }, [
    session.authenticated,
    session.token,
    dispatch,
    pusher.authEndpoint,
    pusher.cluster,
    pusher.enabledTransports,
    pusher.encrypted,
    pusher.key,
    username,
  ])
}
