/**
 * Application configuration
 *
 * Provider and hooks to keep track of data shared between server and client
 * for a particular visit.
 *
 * Main goal of this module is to collect and provide easy access to the data
 * that does not arrive from backend services but collected either from HTTP
 * request or browser environment.
 */

import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import stringify from 'fast-stringify'

import * as Constants from '../constants'
import * as Cookies from '../cookies'
import * as Http from '../http'
import * as Utils from '../utils'
import * as ReduxSession from '../redux-session'

import * as State from './state'

const AppConfigContext = React.createContext([{}, Utils.Functions.noop])

export function Provider(props) {
  const request = Http.useRequest()
  const locale = Http.useLocale()
  const query = Http.useParsedQuery()
  const clientCountryCode = Utils.Http.normalizeCountryCode(
    Http.useRequestHeader('cf-ipcountry')
  )
  const [clientType, setClientType] = Cookies.useCookie(
    Constants.Cookies.CLIENT_TYPE
  )
  const [countryCode, setCountryCode] = Cookies.useCookie(
    Constants.Cookies.COUNTRY_CODE
  )
  const [language, setLanguage] = Cookies.useCookie(Constants.Cookies.LANGUAGE)
  const [devicePixelRatio, setDevicePixelRatio] = Cookies.useCookie(
    Constants.Cookies.DEVICE_PIXEL_RATIO
  )

  const authenticated = ReactRedux.useSelector((state) =>
    ReduxSession.isAuthenticated(state.session)
  )

  const playerLanguage =
    authenticated || query.confirm_locale
      ? locale.language || language
      : language

  const playerCountryCode =
    authenticated || query.confirm_locale
      ? locale.region || countryCode
      : countryCode

  const [state, dispatch] = React.useReducer(
    State.reducer,
    process.browser ? window.__CLIENT_CONFIG__ : State.INITIAL_STATE,
    process.browser
      ? R.identity
      : // Since server-side rendering is done in one pass, we are not able to
        // `dispatch` updates during render as they will not be applied.
        // Therefore we use this hacky state initializer, which dispatches
        // actions manually to have data we need to be applied on server in
        // state beforehand.
        (initialState) =>
          R.reduce(
            State.reducer,
            initialState,
            R.reject(R.isNil, [
              State.updateApiUrl(process.env.API_URL),
              State.updateBetbyConfig({
                brandId: process.env.BETBY_BRAND_ID,
                scriptUrl: process.env.BETBY_SCRIPT_URL,
                themeName: process.env.BETBY_THEME_NAME,
              }),
              State.updateClientCountryCode(locale.region || clientCountryCode),
              State.updateClientType(clientType),
              State.updateDevicePixelRatio(devicePixelRatio),
              State.updateGameServerUrl(process.env.GAME_SERVER_URL),
              State.updateGraphqlUrl(process.env.GRAPHQL_URL),
              State.updateFeatureFlag(
                Constants.Feature.SEON,
                process.env.SEON_ENABLED
              ),
              State.updateLanguage(playerLanguage),
              State.updateOrigin(request),
              State.updatePayerUrl(process.env.PAYER_URL),
              State.updatePlayerCountryCode(playerCountryCode),
            ])
          )
  )

  React.useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    const clientType = isTouchDevice
      ? Constants.ClientType.MOBILE_BROWSER
      : Constants.ClientType.BROWSER

    dispatch(State.updateClientType(clientType))
  }, [dispatch])

  React.useEffect(() => {
    dispatch(State.updateDevicePixelRatio(Math.round(window.devicePixelRatio)))
  }, [dispatch])

  React.useEffect(() => {
    if (playerCountryCode) {
      dispatch(State.updatePlayerCountryCode(playerCountryCode))
    }
  }, [dispatch, playerCountryCode])

  React.useEffect(() => {
    if (playerLanguage) {
      dispatch(State.updateLanguage(playerLanguage))
    }
  }, [dispatch, playerLanguage])

  setClientType(State.getClientType(state), {
    httpOnly: false,
    maxAge: 365 * 24 * 60 * 60,
    path: '/',
  })

  setDevicePixelRatio(State.getDevicePixelRatio(state), {
    httpOnly: false,
    maxAge: 365 * 24 * 60 * 60,
    path: '/',
  })

  setCountryCode(State.getPlayerCountryCode(state), {
    httpOnly: false,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  })

  setLanguage(State.getLanguage(state), {
    httpOnly: false,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  })

  const value = React.useMemo(() => [state, dispatch], [state, dispatch])

  return (
    <React.Fragment>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.__CLIENT_CONFIG__ = ${stringify(state)}`,
        }}
      />
      <AppConfigContext.Provider value={value}>
        {props.children}
      </AppConfigContext.Provider>
    </React.Fragment>
  )
}

Provider.displayName = 'AppConfig.Provider'

Provider.propTypes = {
  children: PropTypes.node,
}

function useContext() {
  return React.useContext(AppConfigContext)
}

export function useApiUrl() {
  const [state] = useContext()
  return State.getApiUrl(state)
}

export function useBetbyConfig() {
  const [state] = useContext()
  return State.getBetbyConfig(state)
}

export function useClientType() {
  const [state] = useContext()
  return State.getClientType(state)
}

export function useCountryCode(confirmed) {
  const [state, dispatch] = useContext()
  const clientCountryCode = State.getClientCountryCode(state)
  const playerCountryCode = State.getPlayerCountryCode(state)

  const value = R.defaultTo(
    confirmed ? null : clientCountryCode,
    playerCountryCode
  )
  const setValue = React.useCallback(
    (countryCode) => {
      dispatch(State.updatePlayerCountryCode(countryCode))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function useDevicePixelRatio() {
  const [state] = useContext()
  return State.getDevicePixelRatio(state)
}

export function useGameServerUrl() {
  const [state] = useContext()
  return State.getGameServerUrl(state)
}

export function useGraphqlUrl() {
  const [state] = useContext()

  if (process.browser) {
    return State.getGraphqlUrl(state)
  }

  return process.env.GRAPHQL_INTERNAL_URL || State.getGraphqlUrl(state)
}

export function useFeatureFlag(name) {
  const [state] = useContext()
  return State.getFeatureFlag(state, { name })
}

export function useLanguage() {
  const [state, dispatch] = useContext()

  const value = State.getLanguage(state)
  const setValue = React.useCallback(
    (language) => {
      dispatch(State.updateLanguage(language))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function useHasWebpSupport() {
  const [state] = useContext()
  return State.getHasWebpSupport(state)
}

export function useHeaderHeight() {
  const [state, dispatch] = useContext()

  const value = State.getHeaderHeight(state)
  const setValue = React.useCallback(
    (height) => {
      dispatch(State.updateHeaderHeight(height))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function useLocale() {
  const [state, dispatch] = useContext()

  const value = State.getLocale(state)
  const setValue = React.useCallback(
    (language, region) => {
      dispatch(State.updateLocale(language, region))
    },
    [dispatch]
  )

  return [value, setValue]
}

export function useOrigin() {
  const [state] = useContext()
  return State.getOrigin(state)
}

export function usePayerUrl() {
  const [state] = useContext()
  return State.getPayerUrl(state)
}
