/**
 * Cookies names dictionary for documentation and consistency purposes
 * @enum
 */

export const Cookies = {
  BTAG: 'BTAG',
  CLIENT_TYPE: 'client_type',
  COOKIE_CONSENT: 'cookie_consent',
  COUNTRY_CODE: 'country_code',
  DEVICE_PIXEL_RATIO: 'dpr',
  LANGUAGE: 'language',
  DEVICE_ID: '_hgdid',
  RETURNING_PLAYER: 'returning_player',
  TOKEN: 'token',
}

export const CookieOptions = {
  [Cookies.BTAG]: {
    httpOnly: true,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  },
  // Hero Gaming Device ID
  // Used to track visitor’s device
  [Cookies.DEVICE_ID]: {
    httpOnly: false,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  },
  [Cookies.TOKEN]: {
    maxAge: 24 * 60 * 60,
    path: '/',
  },
  [Cookies.COOKIE_CONSENT]: {
    maxAge: 365 * 24 * 60 * 60 * 1000,
    path: '/',
  },
  [Cookies.RETURNING_PLAYER]: {
    httpOnly: false,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  },
}

/**
 * Device’s client type
 *
 * "unknown" is not supported by BE and any code relying on client type should
 * not execute further until valid client type is received.
 */
export const ClientType = {
  BROWSER: 'browser',
  MOBILE_BROWSER: 'mobile-browser',
  UNKNOWN: 'unknown',
}

/**
 * Feature flags
 */
export const Feature = {
  SEON: 'seon',
}

export const GameTileWidth = {
  LARGE: 285,
  MEDIUM: 210,
  MIDI: 195,
  SMALL: 140,
}

export const GameTileHeight = {
  LARGE: 296,
  MEDIUM: 224,
  MIDI: 180,
  SMALL: 150,
}

export const GAME_TILE_MULTIPLIER = 0.73

export const GameTileSizes = ['small', 'midi', 'medium', 'large']

export const EmailPattern = '^\\S+@\\S+\\.\\S{2,}$'

export const PasswordPattern =
  "^(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*?#\\-+\\/'^:,(){}[\\]~`_.])[A-Za-z\\d@$!%*?#\\-+\\/'^:,(){}[\\]~`_.]{8,}$"

export const SportsCategories = {
  'football': '/1',
  'kabaddi': '/138',
  'live': '/live',
  'ipl': '/21/1710528586814988288',
  'my-bets': '/bets',
}

export const TransactionType = {
  WITHDRAWAL: 'withdrawal',
  DEPOSIT: 'deposit',
}

export const TimeoutSteps = {
  Initial: 0,
  Confirmation: 1,
}

export const CssVariables = {
  HEADER_WRAPPER_HEIGHT: '--header-height',
  WINDOW_INNER_HEIGHT: '--window-inner-height',
}

export const CONTENT_PAGE_HEIGHT = `calc(var(${CssVariables.WINDOW_INNER_HEIGHT}, 100vh) - var(${CssVariables.HEADER_WRAPPER_HEIGHT}, 0px))`
