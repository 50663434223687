import * as ThemeUI from 'theme-ui'
import PropTypes from 'prop-types'
import React from 'react'

import { buttons } from './buttons'
import { cards } from './cards'
import { forms } from './forms'
import { foundations } from './foundations'
import { layout } from './layout'
import { links } from './links'
import { staticPageTemplate } from './static-pages'
import { styles } from './styles'
import { text } from './text'

const variants = {
  cards,
  buttons,
  styles,
  links,
  layout,
  text,
  forms,
  staticPageTemplate,
}

export function Provider(props) {
  return (
    <ThemeUI.ThemeProvider theme={{ ...foundations, ...variants }}>
      {props.children}
    </ThemeUI.ThemeProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
}
