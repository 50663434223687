import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import PropTypes from 'prop-types'

import { Button } from '../buttons/button'

const DEBUG_ALL = 'all'
const DEBUG_MISSING = 'missing'
const DEBUG_OFF = 'off'

const DegbugTranslationsContext = React.createContext(null)

export function Provider(props) {
  const [debug, setDebugValue] = React.useState(DEBUG_OFF)

  return (
    <DegbugTranslationsContext.Provider value={{ debug, setDebugValue }}>
      {props.children}
    </DegbugTranslationsContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.element,
}

export function useContext() {
  return React.useContext(DegbugTranslationsContext)
}

export function DegbugTranslations() {
  const context = useContext()

  return (
    <>
      <ThemeUI.Box sx={{ fontSize: '20px', textAlign: 'center', mb: '1' }}>
        Debug Translations
      </ThemeUI.Box>
      <ThemeUI.Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => context?.setDebugValue(DEBUG_ALL)}>
          DEBUG_ALL
        </Button>
        <ThemeUI.Box sx={{ px: '1' }}>
          <Button onClick={() => context?.setDebugValue(DEBUG_MISSING)}>
            DEBUG_MISSING
          </Button>
        </ThemeUI.Box>
        <Button onClick={() => context?.setDebugValue(DEBUG_OFF)}>
          DEBUG_OFF
        </Button>
      </ThemeUI.Box>
    </>
  )
}
