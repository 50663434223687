import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import * as Http from './http'
import * as AppConfig from './app-config'

export function Canonical() {
  const { pathname } = ReactRouter.useLocation()
  const origin = AppConfig.useOrigin()
  const localeSlug = Http.useBasename()

  const path = pathname !== '/' ? pathname : ''

  return (
    <Helmet>
      <link rel="canonical" href={`${origin}${localeSlug}${path}`} />
    </Helmet>
  )
}
