import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import { forceCheck } from 'react-lazyload'

/**
 * The Lazyload library we use only checks if the component is inside the viewport
 * on resize or scroll events. And most of our navigation happens without any of these
 * being triggered which in turn makes the component it's supposed to lazyload not show
 * because it thinks it's not in the viewport yet.
 *
 * This hook then makes a forcecheck to check which components are actually inside the
 * viewport and updates their states accordingly.
 */
export function useLazyloadInsideWindowCheck() {
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    // It is delayed to make sure the elements are painted and in the DOM when checking
    const id = setTimeout(() => {
      forceCheck()
    }, 1000)

    return () => {
      clearTimeout(id)
    }
  }, [location.pathname])
}
