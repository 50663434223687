import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import PropTypes from 'prop-types'

export function HtmlContent(props) {
  const html = props.children ? { __html: props.children } : props.html

  return (
    <ThemeUI.Box
      dangerouslySetInnerHTML={html}
      as={props.as}
      sx={{
        'color': 'inherit',
        'fontFamily': 'body',

        'h1, h2, h3, h4': {
          fontFamily: 'heading',
          fontWeight: '600',
        },

        'p': {
          fontSize: 'sm',
        },

        'a': {
          'color': 'primary',
          'textDecoration': 'underline',

          '&:hover, &:active': {
            color: 'primary-hover',
          },
        },
        ...props.sx,
      }}
    />
  )
}

HtmlContent.propTypes = {
  children: PropTypes.string.isRequired,
  sx: PropTypes.object,
  as: PropTypes.string,
  html: PropTypes.shape({ __html: PropTypes.string }),
}
