import * as ReactRouter from 'react-router-dom'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'

/** @jsx jsx */

/**
 * Navigation link component
 * @param props
 * @param {boolean} props.active
 * @param {string} props.to
 * @param {Object} props.children
 * @param {Object} props.sx
 * @param {boolean} props.strict
 */

export function NavLink(props) {
  return (
    <ReactRouter.NavLink
      isActive={(match, location) => {
        if (props.active) {
          return true
        }

        if (props.strict) {
          const paramsArr = R.split('&', R.replace('?', '', location.search))
          const currentUrl = location.pathname + location.search
          const routeMatch = props.to.includes(location.pathname)
          const searchMatch = paramsArr.some((v) => props.to.includes(v))
          return (routeMatch && searchMatch) || currentUrl === props.to
        }

        return location.pathname.indexOf(props.to) === 0
      }}
      to={props.to}
      sx={{
        'fontFamily': 'heading',
        'fontWeight': 'bold',
        'textDecoration': 'none',
        'color': props.disabled ? 'disabled' : 'primary',
        'cursor': props.disabled ? 'not-allowed' : 'pointer',
        '&:focus': {
          color: 'primary-hover',
        },

        '&:hover, &:active': {
          color: props.disabled ? 'disabled' : 'primary-hover',
        },

        ...props.sx,
      }}
    >
      {props.children}
    </ReactRouter.NavLink>
  )
}

NavLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  strict: PropTypes.bool,
  active: PropTypes.bool,
}
