import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import * as Herz from '@rushplay/herz'

import * as Suspense from './suspense'

const getSeoMetadata = `
  query SeoMetadata($url: String!) {
    seoMetadata(url: $url) {
      description
      title
    }
  }
`

function SeoMetaData(props) {
  const translate = Herz.I18n.useTranslate(
    () => [props.description, props.title],
    [props.description, props.title]
  )

  return (
    <Helmet>
      <title>{translate(props.title)}</title>
      {translate(props.description) && (
        <meta name="description" content={translate(props.description)} />
      )}
    </Helmet>
  )
}

SeoMetaData.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
}

export function SeoMetaDataContainer() {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()

  const [response] = Urql.useQuery({
    query: getSeoMetadata,
    variables: { url: location.pathname + location.search },
  })

  if (
    response.fetching ||
    response.error ||
    response.data?.seoMetadata == null
  ) {
    return (
      <Helmet>
        <title>{translate('meta.title')}</title>
        <meta name="description" content={translate('meta.desc')} />
      </Helmet>
    )
  }

  return (
    <Suspense.Boundary fallback={null}>
      <SeoMetaData
        description={response.data?.seoMetadata?.description}
        title={response.data?.seoMetadata.title}
      />
    </Suspense.Boundary>
  )
}

Herz.I18n.Loader.preload(['meta.title', 'meta.desc'], SeoMetaDataContainer)

export default SeoMetaDataContainer
