import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import PropTypes from 'prop-types'

import { Link } from './link'

export function SocialLinks(props) {
  if (props.items?.length === 0) {
    return null
  }

  return (
    <ThemeUI.Flex
      sx={{
        'flexDirection': 'row',
        'alignItems': 'center',

        '> *:not(:last-of-type)': {
          mr: 2,
        },
      }}
    >
      {props.items.map((item, index) => (
        <Link key={`social-link_${index}_${item.name}`} links={item.links}>
          <img
            src={item.image?.url}
            loading="lazy"
            alt={`${item.name}-logo`}
            height="18px"
            width="18px"
          />
        </Link>
      ))}
    </ThemeUI.Flex>
  )
}

SocialLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
      links: PropTypes.object,
      name: PropTypes.string,
      color: PropTypes.string,
    })
  ),
}
