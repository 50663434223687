import * as Urql from 'urql'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

import * as Herz from '@rushplay/herz'

import * as ReduxPlayer from './redux-player'
import * as DebugTranslations from './dev-tools/debug-translations'

const I18nConfigQuery = gql`
  query I18nConfig {
    configuration {
      currency {
        code
      }
      locale {
        slug
        code
      }
      supportEmail
    }
  }
`

export function Provider(props) {
  const [response] = Urql.useQuery({ query: I18nConfigQuery })
  const session = Herz.Auth.useSession()
  const currency = ReactRedux.useSelector((state) =>
    ReduxPlayer.getCurrency(state.player)
  )
  const context = DebugTranslations.useContext()

  const { configuration } = response.data

  const currencyCode = session.token ? currency : configuration.currency.code

  const globals = React.useMemo(
    () => ({
      brandEmail: configuration.supportEmail,
      brandName: 'MyChance',
      currencyCode,
      locale: configuration.locale.slug,
    }),
    [configuration, currencyCode]
  )

  const processors = React.useMemo(
    () => ({
      currency(value, data) {
        return new Intl.NumberFormat(configuration.locale.slug, {
          currency: configuration.currency.code || data.currencyCode,
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
          style: 'currency',
        }).format(value / 100)
      },
    }),
    [configuration.currency.code, configuration.locale.slug]
  )

  return (
    <Herz.I18n.TranslationProvider
      globals={globals}
      language={configuration?.locale?.code}
      processors={processors}
      debug={context?.debug}
    >
      {props.children}
    </Herz.I18n.TranslationProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.element,
}
