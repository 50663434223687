import { styles } from './styles'

const borders = {
  'none': 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '3px': '3px solid',
  '4px': '4px solid',
  '8px': '8px solid',
}

export const breakpoints = ['640px', '768px', '1024px', '1240px']

export const breakpointsToArrayIndex = {
  _: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
}

const colors = {
  'black': '#000000',
  'white': '#FFFFFF',
  'faded-white': '#EAEAEA',
  'bright-green': '#97F08F',

  'nav-bg': '#1C4152',
  'nav-active-bg': '#090D14',
  'dark-bg': '#08151C',
  'drawer-bg': '#1C4152',
  'hero-banner-bg': '#0E242E',
  'body-bg': '#1C4152',

  'primary': '#5CC092',
  'primary-hover': '#1F965F',
  'secondary': '#5CC092',
  'secondary-hover': '#878787',
  'primary-bg': '#151E2D',
  'seo-bg': '#031346',
  'secondary-bg': '#0E242E',
  'primary-text': '#EAEAEA',
  'primary-text-inverted': '#090D14',
  'primary-active': '#151E2D',
  'secondary-text': '#666975',
  'page-bg': '#FAFBFF',
  'page-background': '#fafcff',

  'divider': '#315160',

  'icons-purple': '#5532e2',
  'cookie-banner-background': '#EAEAEA',
  'disabled': '#C2C2C2',
  'promo-card-background': '#F6FAFF',
  'game-tile-background': 'hsl(284, 70%, 5%)',
  'toast-background': '#EAEAEA',
  'toast-background-dark': '#08151C',

  'success': '#54EBB9',
  'warning': '#E25532',
  'danger': '#B44343',
  'info': '#151E2D',

  'promotion-live-casino': '#32bfe2',
  'promotion-sport': '#5532e2',
  'promotion-casino': '#3267e2',
  'promotion-welcome-offer': '#e2ad32',
  'welcome-offer-bg': '#e2ad32',
  'promotion-default': '#1a2537',

  'white-alpha': {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  'black-alpha': {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  'checkbox': {
    checked: '#151E2D',
    focused: '#EAEAEA',
  },

  'input-border': '#315160',
  'input-placeholder': '#758296',
  'input-text': '#EAEAEA',
  'input-hover': 'rgb(255,255,255,0.7)',
  'input-text-disabled': '#758296',
  'input-disabled': '#151E2D',
  'input-disabled-border': '#1F2B3F',
  'input-background': '#0E242E',
  'input': '#c9c9c9',
  'form-label': '#C2C2C2',

  'drawer-button': 'rgba(22, 49, 128, 1)',
  'drawer-button-disabled': 'rgba(22, 49, 128, 0.5)',
}

const radii = {
  0: 4,
  1: 6,
  2: 8,
  full: '9999px',
}

const shadows = {
  1: '0 3px 4px 0 rgba(0, 0, 0, 0.1)',
  2: '0 7px 14px 0 rgba(0, 0, 0, 0.1)',
  3: '0 7px 40px 0 rgba(0, 0, 0, 0.1)',
  4: `0 0 6px ${colors['secondary-bg']}`,
  5: `0 0 6px ${colors['secondary-bg']}, 0 0 6px ${colors['secondary-bg']} inset`,
  6: '0 4px 4px rgba(0, 0, 0, 0.25)',
}

const spacing = {
  px: 1,
  0: 4,
  1: 8,
  2: 16,
  3: 24,
  4: 32,
  5: 40,
  6: 48,
  7: 56,
  8: 64,
  9: 72,
}

const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1024px',
}

const components = {
  'nav-header': '96px',
  'nav-sub-menu': '50px',
}

const sizes = {
  ...spacing,
  ...components,
  container,
}

const typography = {
  letterSpacings: {},

  lineHeights: {
    '0': 0,
    '1': 1,
    '2': 1.25,
    '3': 1.5,
    '4': 1.75,
    '5': 2,
    'xs': '15px',
    'sm': '18px',
    'md': '21px',
    'lg': '24px',
    'xl': '30px',
    '2xl': '36px',
    '3xl': '42px',
  },

  fontWeights: {},

  fonts: {
    heading: `"proxima-nova", sans-serif`,
    body: `"proxima-nova",  sans-serif`,
  },

  fontSizes: {
    'xs': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'xl': '20px',
    '2xl': '22px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '60px',
    '7xl': '72px',
    '8xl': '96px',
    '9xl': '128px',
  },
}

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  medium: 50,
  high: 101,
  higher: 200,
  highest: 1000,
}

export const foundations = {
  breakpoints,
  zIndices,
  radii,
  colors,
  ...typography,
  sizes,
  shadows,
  space: spacing,
  borders,
  styles,
}
