export const links = {
  nav: {
    'fontFamily': 'heading',
    'fontWeight': 'bold',
    'color': 'primary',

    '&.active, &:hover': {
      color: 'primary-hover',
    },
  },
}
