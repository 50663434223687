import * as R from 'ramda'
import Cookie from 'cookie'
import URL from 'url'
import kebabcase from 'kebab-case'
import parseResponseCookies from 'set-cookie-parser'

/**
 * @typedef {Object} LocaleDescriptor
 * @prop {?string} language
 * @prop {?string} region
 */

const LOCALE_RE = /^[a-z]{2}-[a-z]{2}$/i

/**
 * Get locale object from URL
 * @param {?string} url
 * @returns {LocaleDescriptor}
 */
export function localeFromUrl(url) {
  const { pathname } = URL.parse(R.defaultTo('/', url))
  const [locale] = R.split('/', R.defaultTo('/', R.drop(1, pathname)))

  if (typeof locale === 'string' && R.test(LOCALE_RE, locale)) {
    const [language, region] = R.split('-', locale)

    return {
      language: R.toLower(language),
      region: R.toUpper(region),
    }
  }

  return {}
}

/**
 * @typedef {Object} CookieOptions
 * @property {?string} domain
 * @property {?Date} expires
 * @property {?boolean} httpOnly
 * @property {?number} maxAge
 * @property {?string} path
 * @property {?(boolean|"none"|"lax"|"strict")} sameSite
 * @property {?boolean} secure
 */

/**
 * @typedef {Object} CookieDescriptor
 * @property {string} name
 * @property {?string} value
 * @property {CookieOptions} options
 */

/**
 * Update given Set-Cookie header value
 *
 * Replace existing cookie value or append it
 *
 * @param {string|Array.<string>} responseCookies `Set-Cookie` header value
 * @param {CookieDescriptor} cookie
 */
export function setCookie(responseCookies, { name, options, value }) {
  if (typeof responseCookies === 'string') {
    return setCookie([responseCookies], { name, options, value })
  }

  const prevCookies = parseResponseCookies(responseCookies)

  const index = R.findIndex(R.propEq('name', name), prevCookies)

  const cookie = Cookie.serialize(name, value, options)

  if (index >= 0) {
    return R.update(index, cookie, responseCookies)
  }

  return R.append(cookie, responseCookies)
}

export function errorsToTranslationKeys(errors) {
  return R.flatten(
    errors.map((error) => {
      const errors = error.extensions.response.body.errors
      const errorKeys = Object.keys(errors)

      return R.flatten(
        errorKeys.map((errorKey) => {
          const errorValues = errors[errorKey]

          return errorValues.map((errorValue) => {
            // Empty string signifies that its not unique
            if (errorValue === '') {
              return `${errorKey}.unique`
            }
            return `${errorKey}.${kebabcase(errorValue)}`
          })
        })
      )
    })
  )
}

/**
 * Helper function to ensure the header CF-IPCountry always has an acceptable value
 *
 * @param {string} value
 * @returns {string} An acceptable country code
 */
export function normalizeCountryCode(value) {
  /**
   * - XX is used for clients without country code data
   * - T1 is used for clients using the Tor network
   * @link https://support.cloudflare.com/hc/en-us/articles/200168236-Configuring-Cloudflare-IP-Geolocation#12345679
   */
  if (R.includes(value, ['XX', 'T1']) || !value) {
    // Defaulting to Netherlands
    return 'NL'
  }

  return value
}
