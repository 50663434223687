import * as R from 'ramda'
import * as DateFns from 'date-fns'
import * as Reselect from 'reselect'
import kebabCase from 'kebab-case'

import * as Api from '@rushplay/api-client'
import * as Websockets from '@rushplay/websockets'

import * as Formatting from './utils/formatting'

// Constants
export const CLEAR = 'promotions/CLEAR'
export const MARK_ALL_AS_SEEN = 'promotions/MARK_ALL_AS_SEEN'
export const MARK_AS_SEEN = 'promotions/MARK_AS_SEEN'
export const REMOVE = 'promotions/REMOVE'
export const UPDATE = 'promotions/UPDATE'
export const UPDATE_ALL = 'promotions/UPDATE_ALL'
export const SET_ALL = 'promotions/SET_ALL'
export const OPT_IN = 'promotions/OPT_IN'
export const OPT_OUT = 'promotions/OPT_OUT'
export const OPT_OUT_ALL = 'promotions/OPT_OUT_ALL'
const PROMOTION_ADDED = 'socket-service/PROMOTION_ADDED'
const HAS_PROMOTIONS_UPDATED = 'HAS_PROMOTIONS_UPDATED'

// Actions
export function clear() {
  return { type: CLEAR }
}

export function markAllAsSeen() {
  return { type: MARK_ALL_AS_SEEN }
}

export function markAsSeen(key) {
  return {
    type: MARK_AS_SEEN,
    payload: key,
  }
}

export function remove(key) {
  return {
    type: REMOVE,
    payload: key,
  }
}

export function setAll(payload) {
  return {
    type: SET_ALL,
    payload: reducePromotions(payload),
  }
}

function reducePromotions(payload) {
  return R.reduce(
    (acc, item) =>
      R.assoc(
        R.path(['key'], item),
        R.pick(
          [
            'activeFrom',
            'activeTo',
            'campaign',
            'gameTitle',
            'key',
            'loggedInButtonLink',
            'name',
            'previewFrom',
            'previewTo',
            'seen',
            'tags',
            'lobbyBanner',
            'minDepositNumber',
            'maxDepositNumber',
          ],
          item
        ),
        acc
      ),
    {},
    payload
  )
}

export function updateAll(payload) {
  return {
    type: UPDATE_ALL,
    payload: reducePromotions(payload),
  }
}

export function update(promotion) {
  return {
    type: UPDATE,
    payload: promotion,
  }
}

export function optIn(promotionkey) {
  return {
    type: OPT_IN,
    payload: promotionkey,
  }
}

export function optOut(promotionkey) {
  return {
    type: OPT_OUT,
    payload: promotionkey,
  }
}

export function optOutOfAll() {
  return Api.optOutAllDepositCampaigns({
    success: () => ({ type: OPT_OUT_ALL }),
    version: 2,
  })
}

// You need to manually send country-code if you wish to fetch promotions logged out.
export function fetch(countryCode, onSuccess) {
  return Api.fetchPromotions(countryCode, {
    success: (res) => [onSuccess && onSuccess(res), setAll(res.value)],
    version: 2,
  })
}

// Reducer
export function reducer(state = {}, action) {
  switch (action.type) {
    case Websockets.SESSION_EXPIRED:
    case CLEAR: {
      return {}
    }

    case MARK_ALL_AS_SEEN: {
      return R.map(R.assoc('seen', true), state)
    }

    case MARK_AS_SEEN: {
      return R.assocPath([action.payload, 'seen'], true, state)
    }

    case Websockets.PROMOTION_ENDED:
    case Websockets.PROMOTION_PREVIEW_ENDED:
    case REMOVE: {
      return R.dissoc(action.payload, state)
    }

    case UPDATE_ALL: {
      return { ...state, ...action.payload }
    }

    case SET_ALL: {
      return action.payload
    }

    case Websockets.PROMOTION_STARTED:
    case Websockets.PROMOTION_PREVIEW_STARTED:
    case UPDATE: {
      return R.assoc(R.path(['key'], action.payload), action.payload, state)
    }

    case OPT_IN: {
      return R.assocPath(
        [action.payload, 'campaign', 'optInState'],
        'in',
        state
      )
    }

    case OPT_OUT: {
      return R.assocPath(
        [action.payload, 'campaign', 'optInState'],
        'out',
        state
      )
    }

    case OPT_OUT_ALL: {
      // Change all opted 'in' campaigns to 'out'
      return R.reduce(
        (acc, key) => {
          if (R.path([key, 'campaign', 'optInState'], acc) === 'in') {
            return R.assocPath([key, 'campaign', 'optInState'], 'out', acc)
          }
          return acc
        },
        state,
        R.keys(state)
      )
    }

    case PROMOTION_ADDED: {
      return R.assoc('hasPromotionAdded', true, state)
    }

    case HAS_PROMOTIONS_UPDATED: {
      return R.assoc('hasPromotionAdded', action.payload, state)
    }

    default:
      return state
  }
}

// Selectors
export function getDepositCampaigns(state, props) {
  return R.reject(
    (promotion) =>
      R.isNil(promotion.campaign) ||
      !R.pathEq(['campaign', 'rewardTrigger'], 'deposit', promotion) ||
      props.dateNow < DateFns.getTime(DateFns.parseISO(promotion.activeFrom)) ||
      R.pathEq(['campaign', 'claimed'], true, promotion) ||
      R.isEmpty(R.pathOr([], ['campaign', 'rewards'], promotion)),
    state
  )
}

export function getOptedInDepositCampaigns(state, props) {
  const depositCampaigns = getDepositCampaigns(state, props)
  return R.filter((item) => {
    const optInState = item.campaign?.optInState
    return optInState === 'in' || optInState === 'automatic'
  }, depositCampaigns)
}

export function getActivePromotions(state, props) {
  return R.pipe(
    R.reject((promotion) => {
      if (!promotion.activeFrom) {
        return true
      }
      return (
        props.dateNow <= DateFns.getTime(DateFns.parseISO(promotion.activeFrom))
      )
    }),
    R.values
  )(state)
}

export function getFuturePromotions(state, props) {
  return R.pipe(
    R.reject((promotion) => {
      if (!promotion.activeFrom) {
        return true
      }
      return (
        props.dateNow > DateFns.getTime(DateFns.parseISO(promotion.activeFrom))
      )
    }),
    R.values
  )(state)
}

/**
 * Checks if there are any unseen non-lobbyBanner promotions.
 * @param state
 * @returns {Boolean}
 */
export function hasUnseenPromotions(state) {
  return (
    R.findIndex(
      R.pathEq(['seen'], false),
      R.values(R.filter((item) => !item.lobbyBanner, state))
    ) >= 0
  )
}

export function getLobbyBannerPromotions(promotions) {
  return R.filter((promotion) => promotion.lobbyBanner, promotions)
}

/**
 * @param state
 * @returns {Array} of unique tags of non-lobbyBanner promotions
 */
export function getUniquePromotionTags(state) {
  return R.uniq(
    R.flatten(
      R.map((promotion) => {
        if (!promotion.lobbyBanner) {
          return Formatting.mapToKebabCase(promotion.tags)
        }
        return []
      }, state)
    )
  )
}

// Helpers

/**
 * Generates a translation key for the reward
 * @param {Object} reward
 * @returns {String} translation key
 */
export function getRewardTranslationKey(reward) {
  if (reward.blitz) {
    return `promotion.${kebabCase(reward.kind)}.blitz`
  }
  return `promotion.${kebabCase(reward.kind)}`
}

/**
 * Generates translation variables needed for a particular reward content
 * @param {Object} reward
 * @param {number} amountCents currently selected deposit amount
 * @returns {Object} translation variables
 */
export function getRewardTranslationVariables(reward, amountCents) {
  return R.pipe(
    R.pick(['amount', 'betAmountCents', 'gameTitle', 'maximumAmountCents']),
    R.mergeLeft({
      rewardAmountCents: R.min(
        (amountCents * reward.amount) / 100,
        R.path(['maximumAmountCents'], reward)
      ),
    }),
    R.reject(R.isNil)
  )(reward)
}

/**
 * Checks if the reward is disabled for currently selected deposit amount
 * @param {Object} reward
 * @param {number} amountCents currently selected deposit amount
 * @returns {Boolean}
 */
export function isRewardDisabled(reward, amountCents) {
  const depositAmountFromCents = R.path(
    ['depositAmountFilter', 'deposit_amount_from_cents'],
    reward
  )
  const depositAmountToCents = R.path(
    ['depositAmountFilter', 'deposit_amount_to_cents'],
    reward
  )
  return (
    (depositAmountFromCents && amountCents < depositAmountFromCents) ||
    (depositAmountToCents && amountCents > depositAmountToCents)
  )
}

/**
 * Generates minimum deposits needed to claim at least 1 reward in promotion,
 * minimum deposit to fully claim all rewards in promotion and
 * maximum possible rewards in promotion
 * @param {Object} rewards all rewards available in a promotion
 * @returns {Object}
 */
export function getPromotionMinMax(rewards) {
  return {
    minDepositForFullPackageCents: R.reduce(
      (result, item) => {
        if (
          R.includes(R.path(['kind'], item), [
            'MoneyPercentItem',
            'BonusPercentItem',
          ])
        ) {
          // Calculating minimum deposit needed to claim maximumAmountCents
          const minDepositForMaxAmount =
            R.path(['maximumAmountCents'], item) /
            (0.01 * R.path(['amount'], item))
          // Minimum deposit to claim full reward of this kind would be either
          // minimum deposit to claim maximumAmountCents or
          // minimum deposit to claim this reward at all
          const min = R.max(
            minDepositForMaxAmount,
            R.pathOr(
              0,
              ['depositAmountFilter', 'deposit_amount_from_cents'],
              item
            )
          )
          return R.max(min, result)
        }
        return R.max(
          result,
          R.path(['depositAmountFilter', 'deposit_amount_from_cents'], item)
        )
      },
      0,
      rewards
    ),
    minDepositForAnyReward: R.reduce(
      (result, item) =>
        R.min(
          result,
          R.pathOr(
            0,
            ['depositAmountFilter', 'deposit_amount_from_cents'],
            item
          )
        ),
      Infinity,
      rewards
    ),
    maxFixedFeatureTriggers: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'FeatureTriggers'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedFreespins: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'Freespins'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedFreebet: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'Freebet'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedBonusItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'BonusItem'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedMoneyItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'MoneyItem'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxBonusPercentItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'BonusPercentItem'
          ? R.max(result, R.path(['maximumAmountCents'], item))
          : result,
      0,
      rewards
    ),
    maxMoneyPercentItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'MoneyPercentItem'
          ? R.max(result, R.path(['maximumAmountCents'], item))
          : result,
      0,
      rewards
    ),
  }
}

/**
 * Generates translation variables needed for a particular Promotion content.
 * (Used in promotions drawer where we don't have a reward split for content)
 * Will be generated for each reward:
 * - rangeLimits_N - minimum deposit to get this reward
 * - gameTitle_N - game title for this reward, if applicable
 * (for Feature triggers and Freespins)
 * - maximumAmountCents_N - maximum amount cents for the reward, if applicable
 * (For Bonus Percent Item and Money Percent Item)
 * - rewardKind_N - the amount of the appropriate reward
 * @param {Object} rewards all rewards available in a promotion
 * @returns {Object} translation variables
 *
 * @example
 *   {
 *      rangeLimits_1: 1000,
 *      rangeLimits_2: 5000,
 *      gameTitle_1: "Starburst",
 *      gameTitle_2: "Note of Death",
 *      freespins_1: 10,
 *      freespins_2: 30,
 *    }
 */

export function getPromotionTranslationVariables(rewards) {
  const mapIndexed = R.addIndex(R.map)
  return R.pipe(
    mapIndexed((reward, index) => {
      return {
        [`rangeLimits_${index + 1}`]: R.pathOr(
          0,
          ['depositAmountFilter', 'deposit_amount_from_cents'],
          reward
        ),
        [`${R.toLower(R.path(['kind'], reward))}_${index + 1}`]: R.path(
          ['amount'],
          reward
        ),
        [`maximumAmountCents_${index + 1}`]: R.path(
          ['maximumAmountCents'],
          reward
        ),
        [`gameTitle_${index + 1}`]: R.path(['gameTitle'], reward),
      }
    }),
    R.mergeAll,
    R.reject(R.isNil)
  )(rewards)
}

/**
 * @param {Object} state promotions state
 * @param {Object} props
 * @param {string} props.tag tag to filter by, eg 'casino', 'live-casino',
 * 'sports', etc. These are set in backoffice.
 * @param {string} props.fallbackTag tag to filter by if regular tag gives no results
 * @returns {Array} Promotions filtered by the given tag (unifies all tags to kebabCase including spaces),
 * or 'default' as a fallback. If no tags match the argument tag or 'default' then an empty array
 * will be returned
 */
export const getPromotionsFilteredByTag = Reselect.createSelector(
  [(state) => state, (state, props = {}) => props],
  (promotions, props) => {
    if (props.tag === 'all') {
      return promotions
    }
    if (promotions) {
      const verticalTags = R.filter(
        (item) =>
          item.tags &&
          R.any(
            R.equals(Formatting.toKebabCase(props.tag)),
            Formatting.mapToKebabCase(item.tags)
          ),
        R.values(promotions)
      )
      if (verticalTags.length) {
        return verticalTags
      }
    }
    return []
  }
)

export function getHasPromotionAdded(state) {
  return R.propOr(false, 'hasPromotionAdded', state)
}

export function updateHasPromotionsAdded(payload) {
  return {
    type: HAS_PROMOTIONS_UPDATED,
    payload,
  }
}
