import * as React from 'react'

import { Global, css } from '@emotion/react'

export function Fonts() {
  return (
    <Global
      styles={css`
        /* latin */
        @import url('https://p.typekit.net/p.css?s=1&k=uzh6xln&ht=tk&f=137.138.139.140.169.170.171.172.173.174.175.176.5474.5475.25136.25137&a=23635288&app=typekit&e=css');

        @font-face {
          font-family: 'proxima-nova';
          src: url('https://use.typekit.net/af/8738d8/00000000000000007735e611/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
            format('woff2');
          font-display: auto;
          font-style: normal;
          font-weight: 800;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'proxima-nova';
          src: url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
            format('woff2');
          font-display: auto;
          font-style: normal;
          font-weight: 700;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'proxima-nova';
          src: url('https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
            format('woff2');
          font-display: auto;
          font-style: normal;
          font-weight: 600;
          font-stretch: normal;
        }

        @font-face {
          font-family: 'proxima-nova';
          src: url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2');
          font-display: auto;
          font-style: normal;
          font-weight: 400;
          font-stretch: normal;
        }
      `}
    />
  )
}
