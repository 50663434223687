import * as React from 'react'
import * as ThemeUI from 'theme-ui'
import PropTypes from 'prop-types'

import * as AppConfig from '../../app-config'
import { Button } from '../../buttons/button'

export function Link(props) {
  const [language] = AppConfig.useLanguage()
  const linkToSocial = props.links[language]
    ? props.links[language]
    : props.links?.en

  return (
    <Button
      as={ThemeUI.Link}
      href={linkToSocial}
      target="_blank"
      rel="noreferrer"
      sx={{
        alignItems: 'center',
        backgroundColor: 'secondary-bg',
        display: 'flex',
        height: '30px',
        justifyContent: 'center',
        lineHeight: 0,
        padding: '0px',
        width: '30px',
        img: {
          objectFit: 'contain',
          height: 'auto',
          maxHeight: '90%',
          maxWidth: '90%',
          width: 'auto',
        },
      }}
    >
      {props.children}
    </Button>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  links: PropTypes.object,
}
