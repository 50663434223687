import * as Urql from 'urql'
import gql from 'graphql-tag'

const getConfiguration = gql`
  query Config {
    configuration {
      apgSealScriptId
      brand
      environment
      imgproxyUrl
      liveChatKey
      pusher {
        authEndpoint
        cluster
        enabledTransports
        encrypted
        key
      }
      country {
        alpha2
        enabled
        countryCode
      }
      countries {
        alpha2
        name
        countryCode
      }
      currency {
        code
      }
      locale {
        language
        code
        slug
      }
    }
  }
`

export function useConfiguration() {
  const [config] = Urql.useQuery({ query: getConfiguration })

  if (!config.data?.configuration) {
    return {}
  }

  return config.data?.configuration ?? {}
}
