/**
 * Cookie module
 *
 * Set of abstractions for easier work with cookies inside React.
 *
 * Main goal of this module is to provide single API for React components to
 * read and write cookies both on server- and client-side.
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import stringify from 'fast-stringify'

const CookiesContext = React.createContext()

export function Provider(props) {
  // Version is used to alter context value and propagate updates to children.
  // We could use `universal-cookie`’s event listener for changes but it would
  // not work server-side due to `useEffect` being no-op.
  const [version, setVersion] = React.useState(0)

  const incrementVersion = React.useCallback(() => {
    setVersion((version) => version + 1)
  }, [setVersion])

  const getCookie = React.useCallback(
    (name) => {
      return props.cookies.get(name)
    },
    [props.cookies]
  )

  const removeCookie = React.useCallback(
    (name, value, options) => {
      const prevValue = props.cookies.get(name)

      if (prevValue == null) {
        return
      }

      props.cookies.remove(name, value, options)
      incrementVersion()
    },
    [props.cookies, incrementVersion]
  )

  const setCookie = React.useCallback(
    (name, value, options) => {
      const prevValue = props.cookies.get(name)

      if (prevValue === value || prevValue === stringify(value)) {
        return
      }

      props.cookies.set(name, value, options)
      incrementVersion()
    },
    [props.cookies, incrementVersion]
  )

  const value = React.useMemo(
    () => ({
      get: getCookie,
      remove: removeCookie,
      set: setCookie,
      version,
    }),
    [getCookie, removeCookie, setCookie, version]
  )

  return (
    <CookiesContext.Provider value={value}>
      {props.children}
    </CookiesContext.Provider>
  )
}

Provider.displayName = 'Cookies.Provider'

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  cookies: PropTypes.shape({
    get: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
}

export function useContext() {
  return React.useContext(CookiesContext)
}

export function useCookie(name) {
  const cookies = useContext()

  const value = cookies.get(name)
  const setValue = React.useCallback(
    (value, options) => {
      if (value == null) {
        cookies.remove(name, options)
      } else {
        cookies.set(name, value, options)
      }
    },
    [cookies, name]
  )

  return [value, setValue]
}
