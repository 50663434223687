// Logger module
//
// This module is created log application events of various importance. It uses
// Pino as logger (https://getpino.io) as it’s claimed to be small and fast.

// Logging guidelines
//
// - Keep messages concise.
// - Message must start with lowercase letter
// - If message describes start of the process, append ellipsis.
//   Example: "dismantling shenanigans..."
// - For most long running processes there must be “completed” log message
//   Example: "dismantling shenanigans completed"

import * as R from 'ramda'
import * as Wonka from 'wonka'
import createLogger from 'pino'

function getLogLevel() {
  if (process.browser && window.__LOG_LEVEL__) {
    return window.__LOG_LEVEL__
  }

  if (process.env.LOG_LEVEL) {
    return process.env.LOG_LEVEL
  }

  return 'info'
}

// Main logger instance
//
// It shall be used to create child loggers thus not exported. Child loggers
// are used to distinguish where occurred event comes from. See descriptions of
// specific loggers for more info.
const logger = createLogger({
  level: getLogLevel(),
  name: process.env.BRAND,
  prettyPrint: process.env.NODE_ENV !== 'production',
})

// Root logger
//
// Shall be used to report events which are either application-wide or hard to
// categorize. Otherwise use loggers for specific components.
export const root = logger.child({ component: 'ROOT' })

// Outgoing HTTP request logger
export const request = logger.child({ component: 'REQUEST' })

// GraphQL logger
export const graphql = logger.child({ component: 'GRAPHQL' })

// React renderer logger
export const react = logger.child({ component: 'REACT' })

// Server logger
export const server = logger.child({ component: 'SERVER' })

export function exchange({ forward }) {
  return (ops$) =>
    Wonka.pipe(
      ops$,
      Wonka.tap((op) => {
        graphql.debug(R.pick(['key', 'kind'], op), 'querying...')
      }),
      forward,
      Wonka.tap((result) => {
        graphql.debug(R.pick(['key', 'kind'], result), 'querying completed')
      })
    )
}
