export const styles = {
  root: {
    'fontFamily': 'body',
    'fontSize': 'md',
    'lineHeight': 'lg',
    'margin': '0px',
    'backgroundColor': 'body-bg',
    'color': 'faded-white',
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '#cc-button': {
      display: 'none',
    },
    'body': {
      margin: 'inherit',
    },
  },
  h1: {
    margin: '0px',
    fontFamily: 'heading',
    fontSize: ['3xl', null, '5xl'],
    lineHeight: ['2xl', null, '3xl'],
  },
  h2: {
    marginTop: 3,
    marginBottom: '0px',
    fontFamily: 'heading',
    fontSize: ['2xl', null, '24px'],
    lineHeight: ['2xl', null, '28px'],
  },
  h3: {
    marginTop: 3,
    marginBottom: '0px',
    fontFamily: 'heading',
    fontSize: 'xl',
    lineHeight: 'lg',
  },
  h4: {
    marginTop: 2,
    marginBottom: '0px',
    fontFamily: 'heading',
    fontSize: '16px',
    lineHeight: '20px',
  },
  h5: {
    marginTop: 2,
    marginBottom: '0px',
    fontFamily: 'heading',
    fontSize: '13px',
    lineHeight: '20px',
  },
  hr: {
    my: 1,
    mx: 2,
    opacity: '0.2',
    color: 'faded-white',
  },
  a: {
    'fontFamily': 'heading',
    'fontWeight': 500,
    'cursor': 'pointer',
    'color': 'primary',
    'fontSize': 'md',

    '&:hover': {
      color: 'primary-hover',
    },
  },
  p: {
    fontFamily: 'heading',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.03em',
    my: 0,
  },
  ol: {
    p: {
      m: '0px',
    },
  },
  ul: {
    fontSize: '10px',
    p: {
      m: '0px',
    },
  },
}
