import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import { Helmet } from 'react-helmet-async'

import * as Configuration from './redux-configuration'
import * as Http from './http'

const getInitialData = Reselect.createStructuredSelector({
  cookieConsent: () => true,
  environment: Configuration.getEnvironment,
  gtmKey: Configuration.getGtmContainerId,
  isIpInternal: Configuration.isIpInternal,
  websiteName: Configuration.getBrand,
})

export function GoogleTagManager() {
  const config = ReactRedux.useSelector((state) =>
    getInitialData(state.configuration)
  )
  const locale = Http.useLocale()

  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const initialDataLayer = {
    'site.environment': config.environment,
    'site.version': process.env.RAZZLE_APP_VERSION,
    'site.websiteId': locale,
    'site.websiteName': config.websiteName,
    'user.acceptedCookies': config.cookieConsent,
    'user.isInternal': config.isIpInternal,
  }

  // dangerouslySetInnerHTML doesnt seem to work with Helmet script tags.
  // There is a known issue for this here:
  // https://github.com/nfl/react-helmet/issues/516
  return (
    <Helmet>
      <script type="text/javascript">
        {`window.dataLayer = window.dataLayer || []; window.dataLayer.push(${JSON.stringify(
          initialDataLayer
        )});`}
      </script>
      <script>
        {`
          document.addEventListener('DOMContentLoaded', () => {
            setTimeout(initGTM, 5000);
          });
          document.addEventListener('scroll', initGTMOnEvent);
          document.addEventListener('mousemove', initGTMOnEvent);
          document.addEventListener('touchstart', initGTMOnEvent);
          function initGTMOnEvent(event) {
            initGTM();
            event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
          }

          function initGTM() {
            if (window.gtmDidInit) {
              return false;
            }
            window.gtmDidInit = true;

            const script = document.createElement('script');
            const ipmetaScript = document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;

            script.onload = () => {
              dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
            };

            ipmetaScript.onload = () => {
              dataLayer.push(provideGtmPlugin({
                gtmEventKey: 'ipmeta_loaded',
                apiKey: '7cb3198c70efe248e60f1105b2ba77774594e5dff1c034cbe563dc95b96d5026',
              }));
            };

            script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-WLPSVRG5';
            ipmetaScript.src = 'https://ipmeta.io/plugin.js';

            document.head.appendChild(script);
            document.head.appendChild(ipmetaScript);
          }
        `}
      </script>
    </Helmet>
  )
}
