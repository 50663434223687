import * as PropTypes from 'prop-types'
import * as React from 'react'
import * as ThemeUI from 'theme-ui'

export function MenuNotificationBadge(props) {
  return (
    <ThemeUI.Box
      sx={{
        'position': 'relative',
        'display': 'block',
        'boxSizing': 'border-box',
        ...props.sx,

        '&::after': {
          content: '""',
          position: 'absolute',
          top: '0px',
          width: '0.45rem',
          height: '0.45rem',
          backgroundColor: 'primary',
          transform: props.hasUnseenItems ? 'scale(1)' : 'scale(0)',
          borderRadius: '50%',
          ...props.badgePosition,
        },
      }}
    >
      {props.children}
    </ThemeUI.Box>
  )
}

MenuNotificationBadge.defaultProps = {
  badgePosition: { right: '0px' },
}

MenuNotificationBadge.propTypes = {
  children: PropTypes.node.isRequired,
  badgePosition: PropTypes.object,
  hasUnseenItems: PropTypes.bool,
  sx: PropTypes.object,
}
