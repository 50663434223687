import * as R from 'ramda'

import { ClientType } from '../constants'

const SUPPORTED_CLIENT_TYPES = R.values(ClientType)
const EMPTY_OBJECT = Object.freeze({})

export const INITIAL_STATE = {
  apiUrl: null,
  betbyConfig: {
    brandId: null,
    scriptUrl: null,
    themeName: null,
  },
  clientCountryCode: null,
  clientType: ClientType.UNKNOWN,
  devicePixelRatio: 1,
  features: {},
  gameServerUrl: null,
  graphqlUrl: null,
  headerHeight: 0,
  origin: null,
  payerUrl: null,
  playerCountryCode: null,
  preferredLanguages: [],
}

const API_URL_UPDATED = 'API_URL_UPDATED'
const BETBY_CONFIG_UPDATED = 'BETBY_CONFIG_UPDATED'
const CLIENT_COUNTRY_CODE_UPDATED = 'CLIENT_COUNTRY_CODE_UPDATED'
const CLIENT_TYPE_UPDATED = 'CLIENT_TYPE_UPDATED'
const DEVICE_PIXEL_RATIO_UPDATED = 'DEVICE_PIXEL_RATIO_UPDATED'
const FEATURE_FLAG_UPDATED = 'FEATURE_FLAG_UPDATED'
const GAME_SERVER_URL_UPDATED = 'GAME_SERVER_URL_UPDATED'
const GRAPHQL_URL_UPDATED = 'GRAPHQL_URL_UPDATED'
const HEADER_HEIGHT_UPDATED = 'HEADER_HEIGHT_UPDATED'
const LANGUAGE_UPDATED = 'LANGUAGE_UPDATED'
const LOCALE_UPDATED = 'LOCALE_UPDATED'
const ORIGIN_UPDATED = 'ORIGIN_UPDATED'
const PAYER_URL_UPDATED = 'PAYER_URL_UPDATED'
const PLAYER_COUNTRY_CODE_UPDATED = 'PLAYER_COUNTRY_CODE_UPDATED'
const WEBP_SUPPORT_UPDATED = 'WEBP_SUPPORT_UPDATED'

// -- Actions

export function updateApiUrl(apiUrl) {
  return {
    type: API_URL_UPDATED,
    payload: apiUrl,
  }
}

export function updateBetbyConfig({ brandId, scriptUrl, themeName }) {
  return {
    type: BETBY_CONFIG_UPDATED,
    payload: {
      brandId,
      enabled: Boolean(brandId),
      scriptUrl,
      themeName,
    },
  }
}

export function updateClientCountryCode(countryCode) {
  return {
    type: CLIENT_COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updateClientType(clientType) {
  return {
    type: CLIENT_TYPE_UPDATED,
    payload: clientType,
  }
}

export function updateDevicePixelRatio(devicePixelRatio) {
  return {
    type: DEVICE_PIXEL_RATIO_UPDATED,
    payload: devicePixelRatio,
  }
}

export function updateHeaderHeight(height) {
  return {
    type: HEADER_HEIGHT_UPDATED,
    payload: height,
  }
}

/**
 * Convert environment variable value to boolean
 *
 * @param {(""|"0"|"1")} [input] Environment variable value
 * @returns {boolean}
 */
function parseFeatureFlagValue(input) {
  if (input === '1') {
    return true
  }

  if (input != null || input !== '' || input !== '0') {
    // eslint-disable-next-line no-console
    console.error(
      'Warning:',
      'Incorrect feature flag value, expected either "1" or "0".',
      'Received:',
      input
    )
  }

  return false
}

export function updateFeatureFlag(name, input) {
  return {
    type: FEATURE_FLAG_UPDATED,
    payload: {
      name,
      value: parseFeatureFlagValue(input),
    },
  }
}

export function updateGameServerUrl(gameServerUrl) {
  return {
    type: GAME_SERVER_URL_UPDATED,
    payload: gameServerUrl,
  }
}

export function updateGraphqlUrl(graphqlUrl) {
  return {
    type: GRAPHQL_URL_UPDATED,
    payload: graphqlUrl,
  }
}

export function updateLanguage(language) {
  return {
    type: LANGUAGE_UPDATED,
    payload: language,
  }
}

export function updateLocale(language, region) {
  return {
    type: LOCALE_UPDATED,
    payload: { language, region },
  }
}

/**
 * Hack to get correct protocol for given request
 * @param {ClientRequest} req
 * @returns {('http:'|'https:')}
 */
function getOriginProtocol(req) {
  const host = req.headers['host']

  // `localhost:3000` is HTTP
  if (host.startsWith('localhost')) {
    return 'http:'
  }

  // `<computer-name>.local:3000` is HTTP
  if (host.endsWith('.local') || host.includes('.local:')) {
    return 'http:'
  }

  return 'https:'
}

export function updateOrigin(request) {
  // TODO: Detect by connection type
  // Example: const protocol = req.connection.encrypted ? 'https:' : 'http:'
  // Depends on: https://herogaming.atlassian.net/browse/PD-148
  const protocol = getOriginProtocol(request)
  const host = request.headers['host']

  return {
    type: ORIGIN_UPDATED,
    payload: `${protocol}//${host}`,
  }
}

export function updatePayerUrl(payerUrl) {
  return {
    type: PAYER_URL_UPDATED,
    payload: payerUrl,
  }
}

export function updatePlayerCountryCode(countryCode) {
  return {
    type: PLAYER_COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updateWebpSupport(request) {
  return {
    type: WEBP_SUPPORT_UPDATED,
    payload: R.test(/image\/webp/, request.headers.accept),
  }
}

// -- Reducer

export function reducer(state, action) {
  switch (action.type) {
    case API_URL_UPDATED: {
      return R.assoc('apiUrl', action.payload, state)
    }

    case BETBY_CONFIG_UPDATED: {
      return R.assoc('betbyConfig', action.payload, state)
    }

    case CLIENT_COUNTRY_CODE_UPDATED: {
      const nextClientCountryCode = action.payload

      if (R.propEq('clientCountryCode', nextClientCountryCode, state)) {
        return state
      }

      return R.assoc('clientCountryCode', nextClientCountryCode, state)
    }

    case CLIENT_TYPE_UPDATED: {
      const isSupported = R.includes(action.payload, SUPPORTED_CLIENT_TYPES)

      const nextClientType = isSupported ? action.payload : ClientType.UNKNOWN

      return R.assoc('clientType', nextClientType, state)
    }

    case DEVICE_PIXEL_RATIO_UPDATED: {
      // No need to optimize for higher DPR, so cap value to 2
      const nextDevicePixelRatio = Math.min(action.payload, 2)

      return R.assoc('devicePixelRatio', nextDevicePixelRatio, state)
    }

    case HEADER_HEIGHT_UPDATED: {
      return R.assoc('headerHeight', action.payload, state)
    }

    case FEATURE_FLAG_UPDATED: {
      return R.assocPath(
        ['features', action.payload.name],
        action.payload.value,
        state
      )
    }

    case GAME_SERVER_URL_UPDATED: {
      return R.assoc('gameServerUrl', action.payload, state)
    }

    case GRAPHQL_URL_UPDATED: {
      if (process.browser) {
        // Determined on server; must be immutable on client
        return state
      }

      const nextGraphqlUrl = action.payload

      return R.assoc('graphqlUrl', nextGraphqlUrl, state)
    }

    case LANGUAGE_UPDATED: {
      const nextLanguage = action.payload

      return R.assoc('language', nextLanguage, state)
    }

    case LOCALE_UPDATED: {
      const nextLocale = action.payload

      return R.reduce(reducer, state, [
        updateLanguage(nextLocale.language),
        updatePlayerCountryCode(nextLocale.region),
      ])
    }

    case ORIGIN_UPDATED: {
      const nextOrigin = action.payload

      return R.assoc('origin', nextOrigin, state)
    }

    case PAYER_URL_UPDATED: {
      return R.assoc('payerUrl', action.payload, state)
    }

    case PLAYER_COUNTRY_CODE_UPDATED: {
      const nextPlayerCountryCode = action.payload

      return R.assoc('playerCountryCode', nextPlayerCountryCode, state)
    }

    case WEBP_SUPPORT_UPDATED: {
      return R.assoc('hasWebpSupport', action.payload, state)
    }

    default: {
      return state
    }
  }
}

// -- Base selectors

export function getApiUrl(state) {
  return R.pathOr('', ['apiUrl'], state)
}

export function getBetbyConfig(state) {
  return R.pathOr(EMPTY_OBJECT, ['betbyConfig'], state)
}

export function getClientCountryCode(state) {
  return R.pathOr(null, ['clientCountryCode'], state)
}

export function getClientType(state) {
  return R.pathOr(ClientType.UNKNOWN, ['clientType'], state)
}

export function getDevicePixelRatio(state) {
  return R.pathOr(1, ['devicePixelRatio'], state)
}

export function getGameServerUrl(state) {
  return R.pathOr('', ['gameServerUrl'], state)
}

export function getGraphqlUrl(state) {
  return R.path(['graphqlUrl'], state)
}

export function getFeatureFlag(state, props) {
  return R.pathOr(false, ['features', props.name], state)
}

export function getHasWebpSupport(state) {
  return R.pathOr(false, ['hasWebpSupport'], state)
}

export function getLanguage(state) {
  return R.pathOr(null, ['language'], state)
}

export function getHeaderHeight(state) {
  return R.pathOr(0, ['headerHeight'], state)
}

export function getOrigin(state) {
  return R.pathOr(null, ['origin'], state)
}

export function getPayerUrl(state) {
  return R.pathOr('', ['payerUrl'], state)
}

export function getPlayerCountryCode(state) {
  return R.pathOr(null, ['playerCountryCode'], state)
}

// -- Combined selectors

export function getCountryCode(state) {
  return getPlayerCountryCode(state) || getClientCountryCode(state)
}

export function getLocale(state) {
  const language = getLanguage(state)
  const region = getCountryCode(state)

  if (language && region) {
    return { language, region }
  }

  return {}
}
