import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'

export function PrivateRoute(props) {
  const session = Herz.Auth.useSession()

  if (!session.authenticated) {
    return (
      <ReactRouter.Redirect from={props.path} to={props.redirectFallback} />
    )
  }

  return <ReactRouter.Route {...props} />
}

PrivateRoute.propTypes = {
  path: PropTypes.string,
  redirectFallback: PropTypes.string,
}

PrivateRoute.defaultProps = {
  redirectFallback: '/',
}
