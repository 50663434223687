import * as ReactRedux from 'react-redux'

import * as CombinedSelectors from './combined-selectors'

export function useActivePromotions() {
  return ReactRedux.useSelector(
    CombinedSelectors.getActivePromotionsByDepositTierFactory({
      dateNow: new Date(),
    })
  )
}

export function useActivePromotionsHashmap() {
  const activePromotions = useActivePromotions()
  return activePromotions.reduce((hashmap, promotion) => {
    return {
      ...hashmap,
      [promotion.key]: promotion,
    }
  }, {})
}
