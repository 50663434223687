const baseGameText = {
  fontFamily: 'body',
  textTransform: 'capitalize',
  color: 'faded-white',
}

const heading = {
  fontFamily: 'heading',
  fontWeight: 700,
}

export const text = {
  'default': {
    color: 'primary-text',
    fontFamily: 'body',
    fontSize: 'md',
  },

  'game-tile': {
    fontFamily: 'body',
    textTransform: 'capitalize',
    color: 'white-alpha.900',
    textAlign: 'center',
    fontWeight: 600,
    textShadow: '1px 1px 3px black',
  },

  heading,

  'page-heading': {
    ...heading,
    fontSize: 'xl',
    textTransform: 'capitalize',
  },

  'game': {
    small: {
      ...baseGameText,
      fontSize: 'xs',
    },

    medium: {
      ...baseGameText,
      fontSize: ['xs', 'md'],
    },

    large: {
      ...baseGameText,
      fontSize: ['md', 'xl'],
    },
  },

  'error': {
    fontSize: 'xs',
    fontFamily: 'body',
    color: 'danger',
  },
}
