import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as reselect from 'reselect'

import * as Websockets from '@rushplay/websockets'
import * as Herz from '@rushplay/herz'

export const UPDATED = 'session/UPDATED'
export const EXPIRED = 'session/EXPIRED'

const INITIAL_STATE = {
  createdAt: null,
  token: null,
}

export function update(token = null, createdAt = null) {
  return {
    type: UPDATED,
    payload: {
      createdAt,
      token,
    },
  }
}

export function expired(expired) {
  return {
    type: EXPIRED,
    payload: expired,
  }
}

/**
 * Extended `@rushplay/session` reducer
 * @param {SessionState} state
 * @param {FSA} action
 */
export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Websockets.SESSION_EXPIRED:
    case EXPIRED: {
      return {
        ...state,
        expired: action.payload,
      }
    }

    case UPDATED: {
      const createdAt = R.path(['payload', 'createdAt'], action)
      const token = R.path(['payload', 'token'], action)

      if (token) {
        return { createdAt, token, expired: false }
      }

      return INITIAL_STATE
    }

    default: {
      return state
    }
  }
}

// Selectors
function getExpired(state) {
  return R.pathOr(false, ['expired'], state)
}

export function getToken(state) {
  return R.pathOr(null, ['token'], state)
}

export function getCreatedAt(state) {
  return R.pathOr(null, ['createdAt'], state)
}

export const isAuthenticated = reselect.createSelector([getToken], (token) =>
  Boolean(token)
)

// Component
export function KeepReduxSessionUpdated() {
  const session = Herz.Auth.useSession()
  const dispatch = ReactRedux.useDispatch()
  const isExpired = ReactRedux.useSelector((state) => getExpired(state.session))

  // Keep redux state up to date with GQL state
  React.useEffect(() => {
    dispatch(update(session.token))
  }, [session.authenticated, dispatch])

  // Updates GQL if a websocket SESSION_EXPIRE event happened
  // and GQL is still logged in
  React.useEffect(() => {
    if (typeof isExpired === 'boolean' && isExpired) {
      if (session.token) {
        session.destroy()
        window.location.reload()
      }
      dispatch(update())
    }
  }, [dispatch, isExpired, session.destroy, session.token])

  return null
}
