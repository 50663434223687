import * as React from 'react'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

import * as Herz from '@rushplay/herz'

import * as AppConfig from './app-config'

const AmountRendererConfigQuery = gql`
  query AmountRendererConfig {
    configuration {
      currency {
        code
      }

      locale {
        slug
      }
    }
  }
`

const CountryQuery = gql`
  query Countries {
    configuration {
      country {
        localCurrency
      }
    }
  }
`

export const currencySymbols = {
  INR: '₹',
  EUR: '€',
  USD: '$',
  AUD: 'AU$',
  NZD: 'NZ$',
  CAD: 'CA$',
}

export function Amount(props) {
  const [response] = Urql.useQuery({ query: AmountRendererConfigQuery })
  const [countryResponse] = Urql.useQuery({ query: CountryQuery })
  const [locale] = AppConfig.useLocale()
  const session = Herz.Auth.useSession()

  const value = new Intl.NumberFormat(props.locale || locale, {
    currency:
      props.currency ||
      (session?.authenticated
        ? response?.data?.configuration?.currency?.code
        : countryResponse?.data?.configuration?.country?.localCurrency),
    minimumFractionDigits: props.minimumFractionDigits,
    maximumFractionDigits: props.maximumFractionDigits ?? 2,
    style: 'currency',
  }).format(props.children / 100)

  return <span>{value}</span>
}

Amount.propTypes = {
  children: PropTypes.number.isRequired,
  currency: PropTypes.string,
  locale: PropTypes.string,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
}
