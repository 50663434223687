import * as ReactRouter from 'react-router-dom'
import querystring from 'querystring'

/**
 * Only updates specified queries
 * @param {Object} location
 * @param {Object} nextQuery
 * @returns {string} querystring with updated values
 */
export function getSafeUpdateQuery(location, nextQuery) {
  const prevQuery = querystring.parse(location.search.slice(1))

  // Remove any 'me' query if it exists
  // those drawers should always be closed before opening aonther drawer
  Object.keys(prevQuery).forEach((key) => {
    if (prevQuery[key] === 'me') {
      delete prevQuery[key]
    }
  })

  const updatedQuery = { ...prevQuery, ...nextQuery }
  const filteredQuery = Object.fromEntries(
    Object.entries(updatedQuery).filter(
      ([, value]) => value !== null && value !== undefined
    )
  )

  return querystring.stringify(filteredQuery)
}

export function useSafeUpdateQuery(nextQuery) {
  const location = ReactRouter.useLocation()

  return getSafeUpdateQuery(location, nextQuery)
}
