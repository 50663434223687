import * as PropTypes from 'prop-types'
import * as React from 'react'

export function Nothing() {
  return null
}

/** Utility component to choose one of two components to render based on
 * a `test` clause. */
export function Branch({ left, right, test, ...props }) {
  return test
    ? React.cloneElement(left, props)
    : React.cloneElement(right, props)
}

Branch.propTypes = {
  test: PropTypes.bool,
  left: PropTypes.any,
  right: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export function When(props) {
  return props.condition ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : null
}

When.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.any,
}

// ** HACK **
// Removes the trailing slash in the href in the rendered DOM element for the root route.
// Requested for SEO since routes with trailing slashes are marked as duplicates in our robots.txt
// and as such, having links on the site linking to the duplicate page is bad practice.

// Passed via the component prop of a react-router Link
// Example: <Link to="/" component={RootLink}>I go home</Link>
export const RootLink = React.forwardRef(function RootLink(props, ref) {
  function handleClick(event) {
    event.preventDefault()
    props.navigate()
  }
  return (
    <a
      ref={ref}
      {...props}
      /* eslint-disable-next-line react/no-unknown-property */
      navigate={null}
      href={props.href.slice(0, -1)}
      onClick={handleClick}
    />
  )
})

// These props are passed by default via the Link component
RootLink.propTypes = {
  navigate: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
}
