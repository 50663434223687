import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import md5 from 'md5'

import * as Api from '@rushplay/api-client'
import * as Websockets from '@rushplay/websockets'

export const CLEARED = '@rushplay/player/CLEARED'
export const INIT = '@rushplay/player/INIT'
export const BALANCE_UPDATED = '@rushplay/player/BALANCE_UPDATED'
export const WITHDRAW_INFORMATION_UPDATED =
  '@rushplay/player/WITHDRAW_INFORMATION_UPDATED'
export const RECEIPTS_UPDATED = '@rushplay/player/RECEIPTS_UPDATED'
export const TOTAL_DEPOSITS_UPDATED = '@rushplay/player/TOTAL_DEPOSITS_UPDATED'
export const BALANCE_TOTALS_UPDATED = '@rushplay/player/BALANCE_TOTALS_UPDATED'
export const RECEIPTS_CLEARED = '@rushplay/player/RECEIPTS_CLEARED'
export const DEPOSIT_INFORMATION_UPDATED =
  '@rushplay/player/DEPOSIT_INFORMATION_UPDATED'
export const DEPOSIT_METHOD_UPDATED = '@rushplay/player/DEPOSIT_METHOD_UPDATED'

export function init(response) {
  if (response instanceof Error) {
    return {
      type: INIT,
      error: true,
      payload: response,
    }
  }

  return {
    type: INIT,
    payload: {
      affiliateClickId: response.affiliateClickId || '',
      affiliateSubId: response.affiliateSubId || '',
      affiliateId: response.affiliateId || undefined,
      affiliateTag: response.affiliateTag || undefined,
      depositAttempts: response.depositAttempts,
      depositNumber: response.depositNumber || 0,
      cddStatus: response.cdd_status || '',
      countryCallingCode: response.countryCallingCode,
      mobile: response.mobile,
      lastDepositCents: response.lastDepositCents || undefined,
      username: response.username || '',
      currency: response.currency,
      language: response.language,
      countryCode: response.countryCode,
      moneyBalanceCents: response.account.moneyBalanceCents,
      bonusBalanceCents: response.account.bonusBalanceCents,
      birthdate: response.birthdate,
      firstName: response.firstName,
      lastName: response.lastName,
      name: `${response.firstName} ${response.lastName}`,
      email: response.email,
      valueSegment: response.valueSegment,
      mapsPlayerId: response.mapsPlayerId || '',
    },
  }
}

export function clearPlayerInfo() {
  return {
    type: CLEARED,
  }
}

export function updateBalanceCents(newAmount) {
  return {
    type: BALANCE_UPDATED,
    payload: newAmount,
  }
}

export function updateSelectedDepositMethod(method) {
  return {
    type: DEPOSIT_METHOD_UPDATED,
    payload: method,
  }
}

export function updateWithdrawInformation(payload) {
  return {
    type: WITHDRAW_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

function updateDepositInformation(payload) {
  return {
    type: DEPOSIT_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function reducer(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case Websockets.BALANCE_CHANGED: {
      if (state.lastFetched > action.payload.timestamp) {
        return state
      }

      const newBalance = R.pickBy(R.complement(R.isNil), {
        currency: action.payload.currency,
        rubies: action.payload.rubies,
        bonusBalanceCents: action.payload.bonusBalanceCents,
        moneyBalanceCents: action.payload.moneyBalanceCents,
        lastFetched: action.payload.timestamp,
      })

      return R.merge(state, newBalance)
    }

    case Websockets.SESSION_EXPIRED:
    case CLEARED:
      return {}

    case RECEIPTS_UPDATED:
      return {
        ...state,
        currentPage: action.payload.meta.page,
        totalPages: action.payload.meta.total_pages,
        receipts: action.payload.receipts,
      }

    case TOTAL_DEPOSITS_UPDATED:
      return {
        ...state,
        totalDepositCents: action.payload,
      }

    case BALANCE_TOTALS_UPDATED:
      return {
        ...state,
        balanceTotals: action.payload,
      }

    case RECEIPTS_CLEARED:
      return {
        ...state,
        balanceTotals: 0,
        currentPage: 0,
        receipts: undefined,
        totalDepositCents: 0,
        totalPages: 0,
      }

    case WITHDRAW_INFORMATION_UPDATED:
      return {
        ...state,
        withdrawInformation: action.payload,
      }

    case DEPOSIT_METHOD_UPDATED:
      return {
        ...state,
        selectedDepositMethod: action.payload,
      }

    default:
      return state
  }
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateClickId(state) {
  return R.path(['affiliateClickId'], state)
}

export function getAffiliateSubId(state) {
  return R.path(['affiliateSubId'], state)
}

export function getCddStatus(state) {
  return R.path(['cddStatus'], state)
}

export function getTotalDepositCents(state) {
  return R.path(['totalDepositCents'], state)
}

export function getReceipts(state) {
  return R.path(['receipts'], state)
}

export function getTotalPages(state) {
  return R.path(['totalPages'], state)
}

export function getBalanceTotals(state) {
  return R.path(['balanceTotals'], state)
}

export function getValueSegment(state) {
  return R.path(['valueSegment'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateId(state) {
  return R.path(['affiliateId'], state)
}

/**
 * Needed only to generate a btag for google analytics
 * @private
 * @param {Object} state Player state
 * @returns {?string}
 */
function getAffiliateTag(state) {
  return R.path(['affiliateTag'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getBtag(state) {
  const affiliateId = getAffiliateId(state)
  const affiliateTag = getAffiliateTag(state)

  if (!affiliateId || !affiliateTag) {
    return null
  }

  return `${affiliateId}_${affiliateTag}`
}

export function getCurrency(state) {
  return R.pathOr('EUR', ['currency'], state)
}

export function getCountryCode(state) {
  return R.pathOr('', ['countryCode'], state)
}

export function getFirstName(state) {
  return R.pathOr('', ['firstName'], state)
}

export function getLastName(state) {
  return R.pathOr('', ['lastName'], state)
}

export function getPhoneNumber(state) {
  const mobile = R.path(['mobile'], state)
  const countryCallingCode = R.path(['countryCallingCode'], state)
  return mobile && countryCallingCode && `+${countryCallingCode}${mobile}`
}

export function getLanguage(state) {
  return R.pathOr('', ['language'], state)
}

export function getName(state) {
  return R.pathOr('', ['name'], state)
}

export function getEmail(state) {
  return R.pathOr('', ['email'], state)
}

export function getBirthdate(state) {
  return R.pathOr('', ['birthdate'], state)
}

export function usePlayerLanguage() {
  return ReactRedux.useSelector((state) => getLanguage(state.player))
}

export function usePlayerCountryCode() {
  return ReactRedux.useSelector((state) => getCountryCode(state.player))
}

export function usePlayerLocale() {
  const playerLang = usePlayerLanguage()
  const playerRegion = usePlayerCountryCode()
  if (playerLang && playerRegion) {
    return [playerLang, playerRegion].join('-')
  }
  return null
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getUsername(state) {
  return R.path(['username'], state)
}

export function getLastDepositAmount(state) {
  return R.path(['lastDepositCents'], state)
}

export function getSelectedDepositMethod(state) {
  return R.path(['selectedDepositMethod'], state)
}

export function getBalanceCents(state) {
  return R.path(['moneyBalanceCents'], state)
}

export function getBonusBalanceCents(state) {
  return R.path(['bonusBalanceCents'], state)
}

export function getWithdrawInformation(state) {
  return R.path(['withdrawInformation'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getMapsPlayerId(state) {
  return state?.mapsPlayerId || ''
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getPlayerId(state) {
  const username = getUsername(state)
  return typeof username === 'string' ? md5(username) : null
}

export function getDepositCount(state) {
  return state.depositNumber
}

/**
 * @param {Object} state Player state
 * @returns {number} amount of deposit attempts submitted, which could have
 * resulted in successful, failed or cancelled status.
 */
export function getDepositAttempts(state) {
  return R.pathOr(0, ['depositAttempts'], state)
}

export function updateReceipts(payload) {
  return {
    type: RECEIPTS_UPDATED,
    payload,
  }
}

export function clearReceipts() {
  return {
    type: RECEIPTS_CLEARED,
  }
}

export function updateTotalDepositCents(payload) {
  return {
    type: TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}

export function updateBalanceTotals(payload) {
  return {
    type: BALANCE_TOTALS_UPDATED,
    payload,
  }
}

// Component
export function KeepReduxPlayerUpdated() {
  // Don't keep it up to date for now, because GQL player info is static
  // and it will overwrite the actual config from redux
  // Keep redux state up to date with GQL state
  // React.useEffect(() => {
  //   dispatch(init(player))
  // }, [player, dispatch])

  return null
}

/**
 * Fetches and stores player info response to state
 * @memberof rushplay/player
 * @returns {Object} Redux action
 */
export function fetchPlayerInfo(config = {}) {
  // TODO: replace 'fetchSession' with 'fetchPlayerInfo' when 'fetchPlayerInfo' contains player data
  // return api.fetchPlayerInfo({
  //   version: 2,
  //   success: res => playerInfoInit(res.value.result),
  //   failure: res =>
  //     playerInfoInit(
  //       new Error(`errors.${res.value.message || 'general.unknown'}`)
  //     ),
  // })
  return Api.fetchSession({
    version: 1,
    success: (sessionRes) => [
      Api.fetchPlayerInfo({
        version: 2,
        success: (res) => {
          const playerInfo = res.value.result
          const player = sessionRes.value.player

          return [
            init(
              R.mergeAll([
                R.path(['account'], player),
                R.path(['address'], player),
                player,
                playerInfo,
              ])
            ),
            config.success && config.success(playerInfo),
          ]
        },
        failure: (res) => {
          // TODO: Avoid doing this request unconditionally. This error happens
          // if player is locked. We don’t want to spam them with error
          // messages in this case.
          if (res.value.message === 'error.accept-terms-and-conditions') {
            return
          }

          return init(
            new Error(`errors.${res.value.message || 'general.unknown'}`)
          )
        },
      }),
      fetchDepositInformation(),
    ],
    failure: (res) => {
      const error = new Error(
        `errors.${res.value.message || 'general.unknown'}`
      )

      // No need to dispatch a session expired error, session expiry is already
      // handled through Websockets
      if (res.value.message === 'session-expired') {
        return
      }

      return init(error)
    },
  })
}

/**
 * Fetches and stores player’s withdrawal limits and wagering information
 * @returns {Object} Redux action
 */
export function fetchWithdrawInformation() {
  return Api.fetchWithdrawInformation({
    success: (res) => updateWithdrawInformation(res.value),
    failure: (res) => {
      const error =
        res instanceof Error
          ? new Error('errors.fetch-failed')
          : new Error(res.value.message)
      return updateWithdrawInformation(error)
    },
    version: 1,
  })
}

export function fetchDepositInformation() {
  return Api.fetchDepositInformation({
    success: (res) => updateDepositInformation(res.value),
    failure: (res) => {
      // TODO: Avoid doing this request unconditionally. This error happens
      // if player is locked. We don’t want to spam them with error
      // messages in this case.
      if (res.value.message === 'error.accept-terms-and-conditions') {
        return
      }

      const error =
        res instanceof Error
          ? new Error('errors.fetch-failed')
          : new Error(`errors.${res.message || 'general.unknown'}`)
      return updateDepositInformation(error)
    },
    version: 1,
  })
}

export function useBalance() {
  const balanceCents = ReactRedux.useSelector((state) =>
    getBalanceCents(state.player)
  )
  const bonusBalanceCents = ReactRedux.useSelector((state) =>
    getBonusBalanceCents(state.player)
  )

  return {
    balanceCents,
    bonusBalanceCents,
    totalBalanceCents: balanceCents + bonusBalanceCents,
  }
}
